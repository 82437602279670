import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from './ReactGAProxy.js'

export default ({ page }) => {
  if (page) {
    // ReactGA.set({ page: page }) // Update the user's current page
    // ReactGA.pageview(page) // Record a pageview for the given page
  } else {
    const location = useLocation()
    // console.log("Track", location.pathname)
    // location - passed in the second argument array means that the
    // effect is only excecuted when the value of location changes.
    useEffect(() => {
      console.log('Track useEffect', location.pathname)
      ReactGA.set({ page: location.pathname }) // Update the user's current page
      // ReactGA.pageview(location.pathname) // Record a pageview for the given page
      ReactGA.send({ hitType: 'pageview', page: location.pathname })
    }, [location])
  }
  return null
}
