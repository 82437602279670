import { IonContent, IonPage } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import OpMapDisplay from '../components/OpMapDisplay'
import OpMapAoi from '../components/OpMapAoi'
import InvMapDisplay from '../components/InvMapDisplay'
import Track from '../components/Track'

import './MapPage.css'

import invalidateMap from './leaflet-workaround.js'

export default ({
  mapType,
  mapPosition,
  setMapPosition,
  spinnerVis,
  gisGeoJson,
  setGisGeoJson,
  aoiGeoJson,
  setAoiGeoJson,
  overlayOpacity,
  fieldsOpacity,
  displayOpWms,
  priorities,
  dataProbsFlag,
  setDataProbsFlag,
  basemap,
  mapDetail,
  setSpinnerVis,
  setMapDetails,
  mapDetails,
  updateMapsCallback,
  lastMapType
}) => {
  const [mapElement, setMapElement] = useState(null)

  invalidateMap(mapElement)

  // Handle appropriate redirection on window resize where single op or inventory
  // map is currently displayed in mobile view.
  const windowResized = () => {
    // Ionic breakpoint values - https://ionicframework.com/docs/layout/grid
    const md = 768
    if (window.innerWidth > md && mapDetail && window.location.pathname === `/app/${mapDetail.route}`) {
      if (mapDetail.type === 'op') {
        history.push('/app/All')
      } else if (mapDetail.type === 'inv') {
        history.push('/app/Inventory')
      }
    }
  }
  const history = useHistory()
  useEffect(() => {
    window.addEventListener('resize', windowResized)
  })

  let map
  if (mapType === 'select') {
    map = (
      <OpMapAoi
        idPrefix='single'
        type='select'
        mapElement={mapElement}
        setMapElement={setMapElement}
        mapPosition={mapPosition}
        setMapPosition={setMapPosition}
        spinnerVis={spinnerVis}
        gisGeoJson={gisGeoJson}
        setGisGeoJson={setGisGeoJson}
        aoiGeoJson={aoiGeoJson}
        setAoiGeoJson={setAoiGeoJson}
        dataProbsFlag={dataProbsFlag}
        setDataProbsFlag={setDataProbsFlag}
        basemap={basemap}
        setSpinnerVis={setSpinnerVis}
        setMapDetails={setMapDetails}
        mapDetails={mapDetails}
        updateMapsCallback={updateMapsCallback}
        lastMapType={lastMapType}
      />
    )
  } else if (mapDetail.type === 'inv') {
    map = (
      <InvMapDisplay
        idPrefix='single'
        mapDetail={mapDetail}
        mapElement={mapElement}
        setMapElement={setMapElement}
        mapPosition={mapPosition}
        setMapPosition={setMapPosition}
        spinnerVis={spinnerVis}
        gisGeoJson={gisGeoJson}
        aoiGeoJson={aoiGeoJson}
        overlayOpacity={overlayOpacity}
        fieldsOpacity={fieldsOpacity}
        displayOpWms={displayOpWms}
        priorities={priorities}
        dataProbsFlag={dataProbsFlag}
        basemap={basemap}
      />
    )
  } else {
    map = (
      <OpMapDisplay
        idPrefix='single'
        mapDetail={mapDetail}
        mapElement={mapElement}
        setMapElement={setMapElement}
        mapPosition={mapPosition}
        setMapPosition={setMapPosition}
        spinnerVis={spinnerVis}
        gisGeoJson={gisGeoJson}
        aoiGeoJson={aoiGeoJson}
        overlayOpacity={overlayOpacity}
        fieldsOpacity={fieldsOpacity}
        displayOpWms={displayOpWms}
        priorities={priorities}
        dataProbsFlag={dataProbsFlag}
        basemap={basemap}
      />
    )
  }

  return (
    <IonPage>
      <Track />
      <IonContent>
        {map}
      </IonContent>
    </IonPage>
  )
}
