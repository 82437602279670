
import MapPage from './MapPage'
import All from './All'
import DataUncertainty from './DataUncertainty'
import React, { useState, useCallback } from 'react'
import { IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonLabel, IonMenuButton, IonIcon, IonToast, IonContent } from '@ionic/react'
import { Redirect, Route } from 'react-router-dom'
import { navigateOutline, earthOutline, layersOutline } from 'ionicons/icons'
import ReactGA from '../components/ReactGAProxy.js'
import './App.css'
import buffer from '@turf/buffer'

export default ({
  setMapDetails,
  mapDetails,
  displayOpWms,
  overlayOpacity,
  fieldsOpacity,
  basemap
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [mapPosition, setMapPosition] = useState({
    mapId: null,
    zoom: 8,
    centre: { lat: 52.5, lng: -1.5 }
  })

  const [spinnerVis, setSpinnerVis] = useState({ woodland: 'none', birds: 'none', runoff: 'none', pollinators: 'none', wetgrass: 'none', landcover: 'none', hedges: 'none' })
  const [gisGeoJson, setGisGeoJson] = useState(null)
  const [aoiGeoJson, setAoiGeoJson] = useState(null)
  const [priorities, setPriorities] = useState({})
  const [lastMapType, setLastMapType] = useState('op')
  const [dataProbsFlag, setDataProbsFlag] = useState(false)

  const errorTimeout = 'E-Planner map service did not return in a timely manner. It looks like there is a high demand for the service at the moment. Consider trying again later.'
  const errorGeneral = 'There was a problem with E-Planner map service. Consider trying again later.'

  const baseURL = process.env.REACT_APP_IMAGE_SERVER_URL + 'imageapi/'

  function updateMapDetails (id, prop, value) {
    // When you pass a function to a set state function,
    // the first value is the current value.
    // See https://medium.com/@wisecobbler/using-a-function-in-setstate-instead-of-an-object-1f5cfd6e55d1
    setMapDetails(currentMapDetails => {
      const newMapDetails = currentMapDetails.map(md => {
        const nmd = { ...md }
        if (!id || nmd.id === id) {
          nmd[prop] = value
        }
        return nmd
      })
      return newMapDetails
    })
  }

  function updateMaps (mapids, type) {
    // console.log('mapids', mapids)
    if (aoiGeoJson || gisGeoJson) {
      // If GIS boundary used, then get the MBR which may be used instead
      // for some calls.
      let gisGeoJsonMbr = null
      if (!aoiGeoJson && gisGeoJson) {
        const x1 = gisGeoJson.gjson.bbox[0]
        const y1 = gisGeoJson.gjson.bbox[1]
        const x2 = gisGeoJson.gjson.bbox[2]
        const y2 = gisGeoJson.gjson.bbox[3]
        gisGeoJsonMbr = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [[
              [x1, y1],
              [x2, y1],
              [x2, y2],
              [x1, y2],
              [x1, y1]
            ]]
          }
        }
      }

      // Initialise body
      const body = {
        epsg: '3857',
        intermediate: 'false' // Don't generate images from intermediate layers
        // dataversion: location.hostname.includes('localhost') ? '' : '202210'
        // dataversion: '202210'
        // dataversion: location.hostname.includes('localhost') || location.hostname.includes('.staging.') ? '202210' : ''
      }

      // Get priorities if an opportunity map requires fetching
      if (mapDetails.filter(md => md.type === 'op').some(md => mapids.includes(md.id) && md.checked && !md.mapData)) {
        setPriorities({})
        fetch(baseURL + 'priorities', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            epsg: '3857',
            geom: aoiGeoJson !== null ? aoiGeoJson.gjson : gisGeoJsonMbr
          })
        })
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(response.status)
            } else {
              return response.json()
            }
          })
          .then((json) => {
            setPriorities(json)
          }).catch(() => {
            setErrorMessage(errorGeneral)
          })
      }

      // Fetch the op and inv map layers data
      mapDetails.forEach(md => {
        if (mapids.includes(md.id) && md.checked && !md.mapData) {
          console.log('Fetch map data', md.id)

          // Set search area appropriately
          if (md.id === 'hedges' && !aoiGeoJson && gisGeoJson) {
            // For hedges map, if GIS input (either directly or by SBI)
            // then use the actual geometry - not the MBR.
            // Also need to buffer it to pick up bounding hedges.
            const gjsonBuffered = buffer(gisGeoJson.gjson, 0.02, { units: 'kilometers' })
            // body.geom = gisGeoJson.gjson
            body.geom = gjsonBuffered
          } else if (aoiGeoJson) {
            body.geom = aoiGeoJson.gjson
          } else {
            console.log('use', md.id, gisGeoJsonMbr)
            body.geom = gisGeoJsonMbr
          }

          // Show spinner
          setSpinnerVis(state => {
            const nState = { ...state }
            nState[md.id] = ''
            return (nState)
          })

          // Set legend style in payload body
          body.style = md.legendStyle

          // Fetch the map layer
          fetch(baseURL + md.urlpath, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(response.status)
              } else {
                if (md.id === 'hedges') {
                  return response.json()
                } else {
                  return response.blob()
                }
              }
            })
            .then((mapData) => {
              if (mapData) {
                let imgURL
                if (md.id === 'hedges') {
                  // So far, hedges is the only map that returns
                  // geojson. The legend json is also included here
                  // - there is no separate call to get legend data.
                  updateMapDetails(md.id, 'legendData', { stats: mapData.legend })
                  // Set imgURL to returned geojson
                  // console.log('mapData', mapData.geojson)
                  imgURL = mapData.geojson.map(f => {
                    const nf = { ...f }
                    nf.type = 'Feature'
                    nf.properties = {}
                    return nf
                  })
                } else {
                  // Set imgURL to dadta URL created from returned blog
                  imgURL = URL.createObjectURL(mapData)
                }
                updateMapDetails(md.id, 'mapData', imgURL)
                ReactGA.event({
                  category: 'ImageAPI',
                  action: 'Image returned OK',
                  nonInteraction: true
                })
              }
            }).catch((error) => {
              if (error === 'Error: 504') {
                setErrorMessage(errorTimeout)
                ReactGA.event({
                  category: 'ImageAPI',
                  action: 'Timeout failure',
                  nonInteraction: true
                })
              } else {
                setErrorMessage(errorGeneral)
                ReactGA.event({
                  category: 'ImageAPI',
                  action: 'General error',
                  nonInteraction: true
                })
              }
            }).finally(() => {
              // Hide spinner
              setSpinnerVis(state => {
                const nState = { ...state }
                nState[md.id] = 'none'
                return (nState)
              })
            })
        }

        // Fetch legend data, where appropariate, for each map
        if (md.type === 'inv' && mapids.includes(md.id) && md.checked && !md.mapData && md.legendUrlPath) {
          // Is a selected iventory map with a legendUrlPath set and no mapData
          const legendData = {}
          const body2 = { ...body }
          if (md.id === 'landcover' && !aoiGeoJson && gisGeoJson.gjson) {
            // For landcover map, if GIS input (either directly or by SBI)
            // then use the actual geometry - not the MBR.
            body2.geom = gisGeoJson.gjson
            legendData.footnote = '(Areas within boundary)'
          }
          fetch(baseURL + md.legendUrlPath, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body2)
          })
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(response.status)
              } else {
                return response.json()
              }
            })
            .then((json) => {
              legendData.stats = json
              updateMapDetails(md.id, 'legendData', legendData)
            }).catch(() => {
              setErrorMessage(errorGeneral)
            })
        }
      })
    }
  }

  const updateMapsCallback = useCallback(
    // We can't pass the updateMaps function as a property directly
    // because it contravenes a linting rule and impacts performance.
    // Instead we do it via a useCallback hook which memoizes the function
    // (only updating it if any of the dependencies in the array - second
    // argument - change.)
    (type) => {
      const mapids = mapDetails.filter(m => m.type === type).map(m => {
        return m.id
      })
      updateMaps(mapids, type)
    },
    [mapDetails, aoiGeoJson, gisGeoJson]
  )

  function tabChanged (e) {
    console.log('e.detail.tab', e.detail.tab)
    if (e.detail.tab !== 'map') { // Not select AOI tab
      let type
      if (e.detail.tab === 'all' || mapDetails.filter(m => m.type === 'op').find(m => m.id === e.detail.tab)) {
        type = 'op'
      } else if (e.detail.tab === 'inventory' || mapDetails.filter(m => m.type === 'inv').find(m => m.id === e.detail.tab)) {
        type = 'inv'
      }
      setLastMapType(type)
      const mapids = mapDetails.filter(m => m.type === type).map(m => {
        return m.id
      })
      updateMaps(mapids, type)
    }
  }

  return (
  /*
    Prior or 23/02 routes here for the tabbed display were enclosed in a IonReactRouter
    but for some reason, perhaps an update of ionic-react - this led to very strange problems with
    routing here. Remoting the IonReactRouter element fixes problem but causes unit test of
    rendering of this component to fail complaining 'You should not use <Route> outside a <Router>',
    nevertheless that seems to be the only way to get it to work reliably, so disabling unit
    test for now
  */

    <IonContent>
      <IonToast
        id='ionToast'
        isOpen={errorMessage !== ''}
        onDidDismiss={() => setErrorMessage('')}
        message={errorMessage}
        duration={10000}
        position='middle'
        buttons={[
          {
            text: 'Okay',
            role: 'cancel'
          }
        ]}
      />
      <IonTabs onIonTabsDidChange={e => tabChanged(e)}>
        <IonRouterOutlet>
          <Route
            path='/app/Select' render={() =>
              <MapPage
                mapType='select'
                mapPosition={mapPosition}
                setMapPosition={setMapPosition}
                basemap={basemap}
                spinnerVis={spinnerVis}
                gisGeoJson={gisGeoJson}
                setGisGeoJson={setGisGeoJson}
                aoiGeoJson={aoiGeoJson}
                setAoiGeoJson={setAoiGeoJson}
                dataProbsFlag={dataProbsFlag}
                setDataProbsFlag={setDataProbsFlag}
                mapDetails={mapDetails}
                setMapDetails={setMapDetails}
                updateMapsCallback={updateMapsCallback}
                lastMapType={lastMapType}
              />}
          />

          <Route
            path='/app/Inventory' render={() =>
              <All
                type='inv'
                mapDetails={mapDetails}
                setMapDetails={setMapDetails}
                mapPosition={mapPosition}
                setMapPosition={setMapPosition}
                basemap={basemap}
                spinnerVis={spinnerVis}
                gisGeoJson={gisGeoJson}
                aoiGeoJson={aoiGeoJson}
                overlayOpacity={overlayOpacity}
                fieldsOpacity={fieldsOpacity}
                displayOpWms={displayOpWms}
                priorities={priorities}
                dataProbsFlag={dataProbsFlag}
              />}
          />

          <Route
            path='/app/All' render={() =>
              <All
                type='op'
                mapDetails={mapDetails}
                setMapDetails={setMapDetails}
                mapPosition={mapPosition}
                setMapPosition={setMapPosition}
                basemap={basemap}
                spinnerVis={spinnerVis}
                gisGeoJson={gisGeoJson}
                aoiGeoJson={aoiGeoJson}
                overlayOpacity={overlayOpacity}
                fieldsOpacity={fieldsOpacity}
                displayOpWms={displayOpWms}
                priorities={priorities}
                dataProbsFlag={dataProbsFlag}
              />}
          />

          {mapDetails.map((m, i) => {
            // These routes are for mobile display where individual
            // maps each have a tab rather than the composite displays.
            return (
              <Route
                key={m.id}
                path={`/app/${m.route}`} render={() =>
                  <MapPage
                    mapDetail={m}
                    mapType={m.id}
                    mapDetails={mapDetails}
                    setMapDetails={setMapDetails}
                    mapPosition={mapPosition}
                    setMapPosition={setMapPosition}
                    basemap={basemap}
                    spinnerVis={spinnerVis}
                    gisGeoJson={gisGeoJson}
                    aoiGeoJson={aoiGeoJson}
                    overlayOpacity={overlayOpacity}
                    fieldsOpacity={fieldsOpacity}
                    displayOpWms={displayOpWms}
                    priorities={priorities}
                    dataProbsFlag={dataProbsFlag}
                  />}
              />
            )
          })}

          <Route path='/app/DataUncertainty' component={DataUncertainty} exact />
          <Route path='/app' render={() => <Redirect to='/app/Select' />} exact />
        </IonRouterOutlet>

        <IonTabBar slot='top'>
          <IonTabButton className='ion-hide-md-up'>
            <IonMenuButton />
          </IonTabButton>
          <IonTabButton tab='map' href='/app/Select'>
            <IonIcon icon={navigateOutline} />
            <IonLabel className='ion-hide-sm-down'>Select</IonLabel>
          </IonTabButton>
          <IonTabButton className='ion-hide-md-down' tab='inventory' href='/app/Inventory'>
            <IonIcon icon={layersOutline} />
            <IonLabel className='ion-hide-sm-down'>Inventory</IonLabel>
          </IonTabButton>
          <IonTabButton className='ion-hide-md-down' tab='all' href='/app/All'>
            <IonIcon icon={earthOutline} />
            <IonLabel>Opportunities</IonLabel>
          </IonTabButton>

          {mapDetails.map((m, i) => {
          // Create all tabs but hide/show depending on checked state of map. If we only create for checked
          // maps, Ionic tabs falls over when adding a previously undisplayed tab.
            return (
              <IonTabButton key={m.id} hidden={!m.checked} className='ion-hide-md-up' tab={m.id} href={`/app/${m.route}`}>
                <IonIcon icon={m.icon} />
                <IonLabel className='ion-hide-sm-down'>{m.route}</IonLabel>
              </IonTabButton>
            )
          })}
        </IonTabBar>
      </IonTabs>
    </IonContent>
  )
}
