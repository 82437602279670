import React, { useState } from 'react'
import { ImageOverlay, GeoJSON, WMSTileLayer, Pane } from 'react-leaflet'
import { getStyleHedgesFunction } from './LegendHedges'
import Legend from './LegendInv'
import Control from 'react-leaflet-control'
import OpMapBase from './OpMapBase'
import './InvMapDisplay.css'

export default ({
  mapDetail,
  aoiGeoJson,
  gisGeoJson,
  fieldsOpacity,
  overlayOpacity,
  mapPosition,
  setMapPosition,
  mapElement,
  setMapElement,
  spinnerVis,
  basemap
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [legendAttr, setLegendAttr] = useState('hghtcls')

  let mapoverlay = null
  let overlayClass = 0
  if (mapDetail.mapData) {
    const bbox = aoiGeoJson ? aoiGeoJson.bbox : gisGeoJson.bbox
    const bounds = [[bbox.ymin, bbox.xmin], [bbox.ymax, bbox.xmax]]
    if (mapDetail.id === 'hedges') {
      mapoverlay = (
        <GeoJSON
          data={mapDetail.mapData}
          style={getStyleHedgesFunction(legendAttr, overlayOpacity)}
        />
      )
      overlayClass = 'hedgesoverlay'
    } else {
      mapoverlay = (
        <ImageOverlay url={mapDetail.mapData} bounds={bounds} opacity={overlayOpacity} />
      )
      overlayClass = 'lcmoverlay'
    }
  }

  // Field boundary WMS is only displayed at zoom level 13 or above
  let fieldsWMS = null
  if (mapPosition.zoom >= 13) {
    fieldsWMS = (
      <WMSTileLayer
        url={process.env.REACT_APP_FIELDSMAP_HOST + 'cgi-bin/mapserv?map=' + process.env.REACT_APP_FIELDSMAP_FILE}
        opacity={fieldsOpacity}
        layers='Fields'
        format='image/png'
      />
    )
  }
  return (
    <OpMapBase
      title={mapDetail.caption}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      setMapElement={setMapElement}
      mapElement={mapElement}
      mapPosition={mapPosition}
      setMapPosition={setMapPosition}
      spinnerVis={spinnerVis[mapDetail.id]}
      type={mapDetail.id}
      basemap={basemap}
    >
      <Pane className='aoiGjson'>
        <GeoJSON
          key={gisGeoJson ? gisGeoJson.name : null} data={gisGeoJson ? gisGeoJson.gjson : null}
          style={{
            color: '#000000',
            weight: 1,
            opacity: 1,
            fillOpacity: 0
          }}
        />
      </Pane>
      <Pane className='fieldWMS'>
        {fieldsWMS}
      </Pane>
      <Pane className={overlayClass}>
        {mapoverlay}
      </Pane>

      <Control position='bottomright'>
        <Legend mapDetail={mapDetail} legendAttr={legendAttr} setLegendAttr={setLegendAttr} />
      </Control>
    </OpMapBase>
  )
}
