import React from 'react'
import './NationalIndicator.css'

import trafficLightRed from '../images/traffic-light-red-h.png'
import trafficLightAmber from '../images/traffic-light-amber-h.png'
import trafficLightGreen from '../images/traffic-light-green-h.png'

export default ({ priority }) => {
  let trafficLightImage, trafficLightDisplay, priorityText

  if (priority) {
    trafficLightDisplay = ''
    if (priority < 1.5) {
      trafficLightImage = trafficLightGreen
      priorityText = 'High'
    } else if (priority < 2.5) {
      trafficLightImage = trafficLightAmber
      priorityText = 'Medium'
    } else {
      trafficLightImage = trafficLightRed
      priorityText = 'Low'
    }
  } else {
    trafficLightDisplay = 'none'
    trafficLightImage = trafficLightRed // Doesn't matter which
  }

  return (
    <div className='national-indicator' style={{ display: trafficLightDisplay }}>
      <b>National priority</b>
      <div>
        <img src={trafficLightImage} alt='Priorities traffic light' />
        <span>{priorityText}</span>
      </div>
    </div>
  )
}
