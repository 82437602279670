import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'
import Track from '../components/Track'
import LocationPin from '../images/location-pin.PNG'
import RectangleAoi from '../images/rectangle-aoi.PNG'
import GisAoi from '../images/gis-aoi.PNG'
import RpaAoi from '../images/rpa-aoi.PNG'
import DeleteAoi from '../images/delete-aoi.PNG'
import CreateOpMaps from '../images/create-opmaps.PNG'
import TrafficLightGreen from '../images/traffic-light-green.png'
import MapSelection from '../images/map-selection.PNG'
import UserGuideTable from '../components/UserGuideTable'
import Select from '../images/select.png'
import Inventory from '../images/inventory.png'
import Opportunities from '../images/opportunities.png'
// import InventoryLegend from '../images/inventory-legend.png'
import './Page.css'

export default () => {
  return (
    <IonPage>
      <Track />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>User guide</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className='text-page'>
          <div className='guideSection'>
            <p>
              The E-Planner tool contains three tabs:
            </p>
            <img src={Select} style={{ marginRight: '1em' }} alt='Select AOI tab' />
            <img src={Inventory} style={{ marginRight: '1em' }} alt='See current inventory' />
            <img src={Opportunities} style={{ marginRight: '1em' }} alt='Get the opportunity maps' />
          </div>
          <div className='guideSection'>
            <p>
              To get started, make sure you are on the <b>Select</b> tab of the tool. You first need to select the area in which you are interested.
              This could be a single farm, a few fields or several neighbouring farms.
            </p>
            <img src={Select} alt='Select AOI tab' />
            <p>
              <img className='icon guideIcon' src={RectangleAoi} alt='Define rectangle AOI' />
              You can define a rectangular area of interest by clicking this control and drawing a box on the map.
            </p>
            <p>
              <img className='icon guideIcon' src={RpaAoi} alt='Enter RPA SBI for AOI' />
              If you have a Single Business Identifier (SBI) from the Rural Payments Agency, click this button to enter it.
              This will fetch your registered field boundaries and use them as the area of interest.
            </p>
            <p>
              <img className='icon guideIcon' src={GisAoi} alt='Select GIS layer for AOI' />
              Or you can upload a GIS file (shapefile) of your area of interest using this control.
            </p>
            <p>
              <img className='icon guideIcon' src={LocationPin} alt='Centre map on location' />
              Optionally, you can also centre the map on your current location by clicking this button.
            </p>
            <p>
              <img className='icon guideIcon' src={DeleteAoi} alt='Delete AOI' />
              If you want to remove an area of interest from the map, use this control.
            </p>
            <p>
              <img className='icon guideIcon' src={CreateOpMaps} style={{ display: 'inlineBlock', marginRight: '1em' }} alt='Get the opportunity maps' />
              When you have defined an area of interest, click on the magic wand button to load the suitability maps.
            </p>
          </div>
          <div className='guideSection'>
            <p style={{ marginBottom: '0.5em' }}>
              This will move you to the <b>Opportunities</b> tab.
            </p>
            <img src={Opportunities} alt='Get the opportunity maps' />
            <p>
              Panels will fill (after a few seconds) with maps, indicating relative suitability
              for the five different environmental opportunities. The table at the bottom of this
              user guide lists the five opportunities, the factors used to calculate suitability,
              and a variety of example management actions for implementing them in different farming
              systems. The maps will zoom and pan together, so you can explore the relative
              suitability of different areas easily.
            </p>
            <p>
              You can indicate which opportunity maps you want to display (up to a maximum of
              four at any one time) by expanding the  <i>Maps to display</i> item on the main
              menu and ticking the maps you want to see. You can also rearrange the position
              the maps appear by dragging the items up/down in this list.
            </p>
            <img src={MapSelection} alt='Map selection' style={{ width: '100%', maxWidth: '350px' }} />
            <p>
              You can also use the menu on the left to choose between OpenStreetMap and an aerial
              photo backdrop (<i>Basemap options</i>), and alter the transparency of the maps with the
              sliders (<i>Layer transparency</i>). For both of these the maps will respond instantly. You
              can also change the colour scheme from white-green to blue-red (<i>Style for opportunity maps</i>)
              but you’ll need to regenerate the maps with the magic wand button to see the new colours.
            </p>
            <p>
              Note that the maps show the contrast in suitability within the area you selected. The least
              suitable area on your farm might still be better than average compared of the rest of GB!
              The traffic lights show you how the area you’ve selected compares to the national priority
              for each environmental outcome.
            </p>
            <p>
              <img src={TrafficLightGreen} style={{ float: 'left', marginRight: '1em', height: '60px' }} alt='Traffic light indicators' />
              <b>Red</b> – lower priority than average (bottom 50%)<br />
              <b>Amber</b> – higher priority than average (top 50%)<br />
              <b>Green</b> – high priority (top 25%)
            </p>
          </div>
          <div className='guideSection'>
            <p style={{ marginBottom: '0.5em' }}>
              The final tab is the <b>Inventory</b> tab.
            </p>
            <img src={Inventory} alt='Examine current inventory' />
            <p>
              Clicking this will load (after a few seconds) maps of the habitats currently present
              in your area of interest. You can select maps of Land Cover and Hedgerows.
            </p>
            <p>
              Land Cover is calculated from the most recent UKCEH Land Cover Map. The map legend
              gives a breakdown of the area in hectares of each broad habitat present. These maps
              are at 10-metre resolution and so may not show all habitats present (especially
              narrow linear features like field margins) but are intended be used alongside
              the opportunity maps to help select which habitats might form targets for improvement
              or expansion. Switching between the Inventory and Opportunities screens can also
              help show why certain areas are masked out of the opportunity maps (e.g. presence
              of existing habitats) or why they are mapped with particularly high suitability
              (e.g. connecting existing habitat patches).
            </p>
            <p>
              Hedgerows are calculated from the newly released UKCEH Land Cover plus Hedgerows dataset.
              The map legend gives breakdown of the length in metres of each hedgerow height class.
              The hedgerow data may not match the exact location of hedgerows on your farm, but is
              intended be used alongside the opportunity maps to explore which areas within the
              selected landscape have high or low hedgerow cover.
            </p>
            <p>
              You can find out more about Land Cover Map and Land Cover plus Hedgerows data by following these links:
            </p>
            <ul>
              <li><a href='https://www.ceh.ac.uk/data/ukceh-land-cover-maps'>UKCEH Land Cover Maps | UK Centre for Ecology & Hydrology</a></li>
              <li><a href='https://www.ceh.ac.uk/data/ukceh-land-cover-plus-hedgerows-2016-2021-england'>UKCEH Land Cover Plus: Hedgerows 2016-2021 (England) | UK Centre for Ecology & Hydrology</a></li>
            </ul>
            <p>
              <b>
                Environmental opportunities, factors driving the suitability maps, and example
                management options for different farming systems
              </b>
            </p>
            <UserGuideTable />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
