import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'
import Track from '../components/Track'
import './Page.css'
import logo from '../images/UKCEH-Logo_Long_Pos_RGB_720x170.png'

export default () => {
  return (
    <IonPage>
      <Track />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>About UKCEH</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className='text-page'>
          <p>
            The UK Centre for Ecology & Hydrology (UKCEH) is an independent, not-for-profit research institute.
            Our 500 scientists provide the data and insights that researchers, governments and businesses
            need to create a productive, resilient and healthy environment. UKCEH has over 20 years of
            experience in working with farmers to improve environmental delivery on farm and we have
            scientific evidence that providing training and advice to farmers significantly increases the
            effectiveness of environmental management. To find out more about our research or contact us
            visit the websites below:
          </p>
          <ul>
            <li><a target='_blank' rel='noopener noreferrer' href='https://www.ceh.ac.uk/'>UKCEH website</a></li>
            <li><a target='_blank' rel='noopener noreferrer' href='https://www.ceh.ac.uk/our-science/science-challenges/sustainable-agriculture'>UKCEH Sustainable agriculture</a></li>
            <li><a target='_blank' rel='noopener noreferrer' href='https://www.ceh.ac.uk/about-us/contact-us'>UKCEH contacts</a></li>
          </ul>
          <img src={logo} style={{ width: '100%', maxWidth: '300px' }} alt='UKCEH logo' />
        </div>
      </IonContent>
    </IonPage>
  )
}
