/*

// The code below worked in @ionic/react@4.11.3 but not in 4.11.4
// but is addressed in @ionic/react@4.11.5-dev.201911082323.5ff786a
// (see https://github.com/ionic-team/ionic/issues/19873)

import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react'
useIonViewDidEnter(function() {
  mapElement && mapElement.current.leafletElement.invalidateSize()
}, [mapElement])

// Doesn't work for latest react/inonic/leaflet (03/04/2020)
// useIonViewDidEnter fires before mapElement is assigned a
// value and therefore cannot invalidate the map

// The hack in this module works around it.
*/

function invalidateIfRequired (map, leafletElement, time) {
  setTimeout(() => {
    if (leafletElement._size.x === 0 && leafletElement._size.y === 0) {
      leafletElement.invalidateSize()
    }
  }, time)
}

export default (map) => {
  // console.log('map.current', map.current)
  if (map && map.current) {
    // WORKAROUND to invalid Leaflet map on first display
    const leafletElement = map.current.leafletElement
    if (leafletElement && leafletElement._size && leafletElement._size.x === 0 && leafletElement._size.y === 0) {
      invalidateIfRequired(map, leafletElement, 100)
      invalidateIfRequired(map, leafletElement, 200)
      invalidateIfRequired(map, leafletElement, 300)
      invalidateIfRequired(map, leafletElement, 400)
      invalidateIfRequired(map, leafletElement, 500)
      invalidateIfRequired(map, leafletElement, 600)
      invalidateIfRequired(map, leafletElement, 700)
      invalidateIfRequired(map, leafletElement, 800)
      invalidateIfRequired(map, leafletElement, 900)
      invalidateIfRequired(map, leafletElement, 1000)
      invalidateIfRequired(map, leafletElement, 1500)
      invalidateIfRequired(map, leafletElement, 2000)
      invalidateIfRequired(map, leafletElement, 2500)
      invalidateIfRequired(map, leafletElement, 3000)
    }
  }
}
