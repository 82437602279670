import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { IonReorder, IonReorderGroup, IonCheckbox, IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRange, IonRadioGroup, IonRadio } from '@ionic/react'
import { informationCircleOutline, informationCircleSharp, helpCircleOutline, helpCircleSharp, calendarOutline, calendarSharp, appsOutline, appsSharp, peopleOutline, peopleSharp } from 'ionicons/icons'
import Legend from './Legend'
import OptsList from './OptsList'
import './Menu.css'
import Logo from '../images/e-planner_white.png'

const appPages = [
  {
    title: 'About E-Planner',
    url: '/About',
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleSharp
  },
  {
    title: 'User guide',
    url: '/UserGuide',
    iosIcon: helpCircleOutline,
    mdIcon: helpCircleSharp
  },
  {
    title: 'E-Planner tool',
    url: '/app/Select',
    iosIcon: appsOutline,
    mdIcon: appsSharp
  },
  {
    title: 'Next steps',
    url: '/NextSteps',
    iosIcon: calendarOutline,
    mdIcon: calendarSharp
  },
  {
    title: 'About UKCEH',
    url: '/ukceh',
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleSharp
  },
  {
    title: 'Acknowledgements',
    url: '/Acknowledgements',
    iosIcon: peopleOutline,
    mdIcon: peopleSharp
  }
]

// const labels = ['Other stuff A', 'Other stuff B', 'Other stuff C']

export default ({
  setMapDetails,
  mapDetails,
  setOverlayOpacity,
  overlayOpacity,
  setFieldsOpacity,
  fieldsOpacity,
  setDisplayOpWms,
  displayOpWms,
  basemap,
  setBasemap
}) => {
  const [legendStyle, setLegendStyle] = useState('rev_terrain')
  const [visOpts, setVisOpts] = useState('')
  const location = useLocation()

  function setOverlayOpacityValue (v) {
    setOverlayOpacity(1 - v)
  }

  function setFieldsOpacityValue (v) {
    setFieldsOpacity(1 - v)
  }

  function doReorder (type, event) {
    setMapDetails(currentMapDetails => {
      let opMapDetails = currentMapDetails.filter(m => m.type === 'op').map(m => { return { ...m } })
      let invMapDetails = currentMapDetails.filter(m => m.type === 'inv').map(m => { return { ...m } })
      if (type === 'op') {
        opMapDetails = event.detail.complete(opMapDetails)
      } else {
        invMapDetails = event.detail.complete(invMapDetails)
      }
      return [...opMapDetails, ...invMapDetails]
    })
  }

  function mapCheckboxChanged (map, e) {
    setMapDetails(currentMapDetails => {
      const newMapDetails = currentMapDetails.map(md => {
        const nmd = { ...md }
        if (nmd.id === map) nmd.checked = e.detail.checked
        return nmd
      })

      const mapchoice = newMapDetails.map(m => {
        return `${m.id}-${m.checked ? 't' : 'f'}`
      })
      localStorage.setItem('mapchoice', mapchoice.join(','))

      return newMapDetails
    })
  }

  const countOpChecked = mapDetails.filter(m => m.type === 'op').reduce((a, m) => m.checked ? a + 1 : a, 0)
  const countInvChecked = mapDetails.filter(m => m.type === 'inv').reduce((a, m) => m.checked ? a + 1 : a, 0)
  return (
    <IonMenu contentId='main' type='overlay'>
      <IonContent>
        <img src={Logo} style={{ width: '80%', maxWidth: '278px' }} alt='E-planner logo' />

        <IonList id='menu-main-list' style={{ paddingTop: '0' }}>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={appPage.title} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection='none' lines='none' detail={false}>
                  <IonIcon slot='start' icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )
          })}
        </IonList>
        <OptsList
          id='opts-map-types'
          header='Choose opportunity maps'
          fontWeight='bold'
          visOpts={visOpts}
          setVisOpts={setVisOpts}
        >
          <IonReorderGroup disabled={false} onIonItemReorder={e => doReorder('op', e)}>
            {mapDetails.filter(m => m.type === 'op').map(m => {
              return (
                <IonItem key={m.id}>
                  <IonReorder slot='start' />
                  <IonLabel>{m.caption}</IonLabel>
                  <IonCheckbox
                    slot='end'
                    color='primary'
                    checked={m.checked} onIonChange={e => mapCheckboxChanged(m.id, e)}
                    disabled={(function () {
                      if (countOpChecked === 4 && !m.checked) {
                        return true
                        // Always need at least one checked
                      } else if (countOpChecked === 1 && m.checked) {
                        return true
                      } else {
                        return false
                      }
                    }())}
                  />
                </IonItem>
              )
            })}
          </IonReorderGroup>
        </OptsList>

        <OptsList
          id='opts-ecap-map-types'
          header='Choose inventory maps'
          fontWeight='bold'
          visOpts={visOpts}
          setVisOpts={setVisOpts}
        >
          <IonReorderGroup disabled={false} onIonItemReorder={e => doReorder('inv', e)}>
            {mapDetails.filter(m => m.type === 'inv').map(m => {
              return (
                <IonItem key={m.id}>
                  <IonReorder slot='start' />
                  <IonLabel>{m.caption}</IonLabel>
                  <IonCheckbox
                    slot='end'
                    color='primary'
                    checked={m.checked} onIonChange={e => mapCheckboxChanged(m.id, e)}
                    disabled={(function () {
                      if (countInvChecked === 4 && !m.checked) {
                        return true
                      } else if (countInvChecked === 1 && m.checked) {
                        // Always need at least one checked
                        return true
                      } else {
                        return false
                      }
                    }())}
                  />
                </IonItem>
              )
            })}
          </IonReorderGroup>
        </OptsList>

        <OptsList
          id='opts-map-baselayer'
          header='Basemap options'
          visOpts={visOpts}
          setVisOpts={setVisOpts}
        >
          <IonRadioGroup value={basemap} onIonChange={e => setBasemap(e.detail.value)}>
            <IonItem lines='none'>
              <IonLabel>OpenStreetMap</IonLabel>
              <IonRadio slot='start' value='osm' />
            </IonItem>
            <IonItem lines='none'>
              <IonLabel>Satellite</IonLabel>
              <IonRadio slot='start' value='satellite' />
            </IonItem>
          </IonRadioGroup>
        </OptsList>
        <OptsList
          id='opts-layer-transparency'
          header='Layer transparency'
          visOpts={visOpts}
          setVisOpts={setVisOpts}
        >
          <IonItem lines='none'>
            <IonLabel position='floating'>Opportunity/Inventory map layer</IonLabel>
            <IonRange className='opacitySlider' min={0} max={1} step={0.1} value={1 - overlayOpacity} onIonChange={e => setOverlayOpacityValue(e.detail.value)} />
          </IonItem>
          <IonItem lines='none'>
            <IonLabel position='floating'>Field boundary</IonLabel>
            <IonRange className='opacitySlider' min={0} max={1} step={0.1} value={1 - fieldsOpacity} onIonChange={e => setFieldsOpacityValue(e.detail.value)} />
          </IonItem>
        </OptsList>
        <OptsList
          id='opts-map-style'
          header='Style for opportunity maps'
          visOpts={visOpts}
          setVisOpts={setVisOpts}
        >
          <IonRadioGroup
            value={legendStyle}
            onIonChange={e => {
              mapDetails.filter(md => md.type === 'op').forEach(md => { md.legendStyle = e.detail.value })
              setLegendStyle(e.detail.value)
            }}
          >
            <IonItem lines='none'>
              <IonLabel>Green to White</IonLabel>
              <IonRadio slot='start' value='rev_terrain' />
            </IonItem>
            <IonItem lines='none'>
              <IonLabel>Red to Blue</IonLabel>
              <IonRadio slot='start' value='blue_orange_red' />
            </IonItem>
            <IonItem lines='none'>
              <Legend lstyle={legendStyle} />
            </IonItem>
          </IonRadioGroup>
        </OptsList>
      </IonContent>
    </IonMenu>
  )
}
