import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import React from 'react'
import Track from '../components/Track'
import './Page.css'
import './DataUncertainty.css'

export default () => {
  const history = useHistory()
  const handleClick = () => {
    history.goBack()
  }
  return (
    <IonPage>
      <Track />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Data uncertainty</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className='text-page'>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            rutrum facilisis mi, vel dapibus mauris fermentum nec. Praesent
            a dignissim urna. Curabitur hendrerit euismod turpis eu congue.
            Sed sagittis vestibulum rhoncus. Proin congue neque lectus, ac
            molestie lacus mattis sed. Nunc eu elit nec purus mattis efficitur.
            Integer molestie auctor ipsum ut dapibus.
          </p>
          <p>
            Nulla eget mi quis eros malesuada malesuada. Vestibulum tempor
            orci eu euismod faucibus. Vivamus vestibulum dictum erat, ac
            convallis ligula porta vel. Pellentesque habitant morbi tristique
            senectus et netus et malesuada fames ac turpis egestas. Morbi
            vestibulum tincidunt mi, eu tempor justo. Sed a euismod nulla.
            Praesent cursus vel metus eu ornare. Pellentesque interdum varius
            rhoncus. Donec sem est, semper posuere luctus non, placerat
            ac ligula. Aliquam suscipit finibus erat.
          </p>
          <p><button className='link' onClick={handleClick}>Go Back to map display.</button></p>
        </div>
      </IonContent>
    </IonPage>
  )
}
