import Menu from './components/Menu'
import Page from './pages/Page'
import UserGuide from './pages/UserGuide'
import About from './pages/About'
import NextSteps from './pages/NextSteps'
import UKCEH from './pages/Ukceh'
import Acknowledgements from './pages/Acknowledgements'
import App from './pages/App'
import React, { useState } from 'react'
import { IonApp, IonRouterOutlet, IonSplitPane, IonContent, IonModal, IonButton } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Redirect, Route } from 'react-router-dom'
import ReactGA from './components/ReactGAProxy.js'
import Track from './components/Track'
import TermsAndConditions from './components/TermsAndConditions'
// import SplashImage from './images/E-Planner_Logo_UpDated120321.jpg'
import SplashImage from './images/e-planner_white.png'
import iconBird from './images/icons/bird-head-outline.svg'
import iconRain from './images/icons/rain.svg'
import iconBee from './images/icons/bee-insect-outline.svg'
import iconLeaf from './images/icons/leaf.svg'
import iconGrass from './images/icons/grass.svg'
import iconLandcover from './images/icons/landcover.svg'
import iconHedges from './images/icons/hedges.svg'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

const opmaps = [
  {
    type: 'op',
    id: 'woodland',
    route: 'Woodland',
    checked: false,
    status: '',
    caption: 'Woodland creation',
    icon: iconLeaf,
    urlpath: 'woodland',
    mapData: 0,
    wmsLayer: 'Woodland',
    legendStyle: 'rev_terrain'
  },
  {
    type: 'op',
    id: 'runoff',
    route: 'Runoff',
    checked: false,
    status: '',
    caption: 'Water resource protection',
    icon: iconRain,
    urlpath: 'runoff',
    mapData: 0,
    wmsLayer: 'Water protection',
    legendStyle: 'rev_terrain'
  },
  {
    type: 'op',
    id: 'birds',
    route: 'Birds',
    checked: false,
    status: '',
    caption: 'Seed-rich bird habitat',
    icon: iconBird,
    urlpath: 'birds',
    mapData: 0,
    wmsLayer: 'Bird food',
    legendStyle: 'rev_terrain'
  },
  {
    type: 'op',
    id: 'pollinators',
    route: 'Pollinators',
    checked: false,
    status: '',
    caption: 'Flower-rich pollinator habitat',
    icon: iconBee,
    urlpath: 'pollinators',
    mapData: 0,
    wmsLayer: 'Pollinator habitat',
    legendStyle: 'rev_terrain'
  },
  {
    type: 'op',
    id: 'wetgrass',
    route: 'Wetgrass',
    checked: false,
    status: '',
    caption: 'Wet grassland restoration',
    icon: iconGrass,
    urlpath: 'wetgrass',
    mapData: 0,
    wmsLayer: 'Wet grassland',
    legendStyle: 'rev_terrain'
  },
  {
    type: 'inv',
    id: 'landcover',
    route: 'Landcover',
    checked: false,
    caption: 'Land cover',
    icon: iconLandcover,
    urlpath: 'lcm',
    mapData: 0,
    legendUrlPath: 'lcm-data',
    legendStyle: 'land_cover_map',
    legendData: null
  },
  {
    type: 'inv',
    id: 'hedges',
    route: 'Hedges',
    checked: false,
    caption: 'Hedgerows',
    icon: iconHedges,
    urlpath: 'hedges',
    mapData: 0,
    legendUrlPath: '',
    legendStyle: 'hedges_test',
    legendData: null
  }
]

// Reinitialise selected opportunity maps from local storage
const mapchoice = localStorage.getItem('mapchoice')

if (mapchoice) {
  const mapChoiceSel = {}
  mapchoice.split(',').forEach(m => {
    const id = m.split('-')[0]
    const displayed = m.split('-')[1] === 't'
    mapChoiceSel[id] = displayed
  })
  opmaps.forEach(m => {
    if (mapChoiceSel[m.id]) {
      m.checked = mapChoiceSel[m.id]
    }
  })
} else {
  opmaps.filter(m => m.type === 'op').forEach((m, i) => {
    if (i < 4) {
      m.checked = true
    }
  })
  opmaps.filter(m => m.type === 'inv').forEach((m, i) => {
    if (i < 4) {
      m.checked = true
    }
  })
}

export default () => {
  // ReactGA.initialize('UA-19063127-27', { debug: process.env.REACT_APP_GA_DEBUG === 'T' }) // UA deprecated
  ReactGA.initialize('G-0J1T5ZMKLK') // GA4

  const [showSplash, setShowSplash] = useState(true)
  const [overlayOpacity, setOverlayOpacity] = useState(0.8)
  const [fieldsOpacity, setFieldsOpacity] = useState(0.5)
  const [displayOpWms, setDisplayOpWms] = useState(false)
  const [mapDetails, setMapDetails] = useState(opmaps)
  const [basemap, setBasemap] = useState('osm')

  setTimeout(() => { setShowSplash(false) }, 3000)

  return (
    <IonApp>
      <Track page='/' />
      <IonContent>
        <TermsAndConditions />
        <IonModal isOpen={showSplash}>
          <div style={{ position: 'relative', height: '100%' }}>
            <img src={SplashImage} style={{ position: 'absolute', maxWidth: '100%', maxHeight: '100%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} alt='E-planner logo' />
          </div>
          <IonButton style={{ display: 'none' }} onClick={() => setShowSplash(false)}>Close Modal</IonButton>
        </IonModal>

        <IonReactRouter>
          <IonSplitPane when='md' contentId='main'>
            <Menu
              setMapDetails={setMapDetails} mapDetails={mapDetails}
              setDisplayOpWms={setDisplayOpWms} displayOpWms={displayOpWms}
              setOverlayOpacity={setOverlayOpacity} overlayOpacity={overlayOpacity}
              setFieldsOpacity={setFieldsOpacity} fieldsOpacity={fieldsOpacity}
              setBasemap={setBasemap} basemap={basemap}
            />
            <IonRouterOutlet id='main'>
              <Route path='/page/:name' component={Page} exact />
              <Route path='/About' component={About} exact />
              <Route path='/UserGuide' component={UserGuide} exact />
              <Route path='/NextSteps' component={NextSteps} exact />
              <Route path='/ukceh' component={UKCEH} exact />
              <Route path='/Acknowledgements' component={Acknowledgements} exact />
              {/* <Route path="/app" component={App} /> */}
              <Route
                path='/app' render={() => {
                  return (
                    <App
                      setMapDetails={setMapDetails}
                      mapDetails={mapDetails}
                      overlayOpacity={overlayOpacity}
                      fieldsOpacity={fieldsOpacity}
                      displayOpWms={displayOpWms}
                      basemap={basemap}
                    />
                  )
                }}
              />
              <Redirect from='/' to='/About' exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonContent>
    </IonApp>
  )
}
