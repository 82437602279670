import ReactGA from 'react-ga4'

const gaEnabled = process.env.REACT_APP_GA_DISABLED !== 'T'
// const gaEnabled = true

console.log('process.env.REACT_APP_GA_DISABLED', process.env.REACT_APP_GA_DISABLED)
console.log('gaEnabled', gaEnabled)

export default {
  initialize: function (...args) {
    if (gaEnabled) {
      ReactGA.initialize(...args)
    }
  },
  event: function (...args) {
    if (gaEnabled) {
      ReactGA.event(...args)
    }
  },
  set: function (...args) {
    if (gaEnabled) {
      ReactGA.set(...args)
    }
  },
  send: function (...args) {
    if (gaEnabled) {
      ReactGA.send(...args)
    }
  }
  // pageview: function (...args) {
  //   if (gaEnabled) {
  //     ReactGA.pageview(...args)
  //   }
  // }
}
