import React, { useState } from 'react'
import { IonModal, IonButton } from '@ionic/react'
import './TermsAndConditions.css'

export default () => {
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)

  // Set globally available function
  window.termsAndConditions = () => {
    setShowTermsAndConditions(true)
  }

  return (
    <IonModal isOpen={showTermsAndConditions}>
      <div style={{ margin: '1em' }}>
        Terms and conditions of use relating to field boundary and opportunity map data:
        <ol>
          <li>You are granted a non-exclusive, royalty free, revocable licence solely
            to view the Licensed Data for non-commercial purposes for
            the period during which Natural Environment Research Council makes it available;
          </li>
          <li>you are not permitted to copy, sub-license, distribute,
            sell or otherwise make available the Licensed Data to third parties in any form; and
          </li>
          <li>third party rights to enforce the terms of this licence shall be reserved to Ordnance Survey.</li>
        </ol>
      </div>
      <IonButton onClick={() => setShowTermsAndConditions(false)}>Close</IonButton>
    </IonModal>
  )
}
