import React from 'react'
import './Legend.css'

export default ({ lstyle }) => {
  let h, mh, ml, l
  if (lstyle === 'rev_terrain') {
    h = '#2BB500'
    mh = '#E5E304'
    ml = '#EAB89D'
    l = '#FFFFFF'
  } else if (lstyle === 'blue_orange_red') {
    h = '#FF3E2D'
    mh = '#FF8F2F'
    ml = '#AEC9E7'
    l = '#3638FC'
  }

  return (
    <div className='oppmap-legend'>
      <div className='oppmap-legend-title'>Local priority</div>
      <br /><div className='oppmap-legend-swatch' style={{ backgroundColor: h }} /><span>High</span>
      <br /><div className='oppmap-legend-swatch' style={{ backgroundColor: mh }} /><span>Medium/High</span>
      <br /><div className='oppmap-legend-swatch' style={{ backgroundColor: ml }} /><span>Medium/Low</span>
      <br /><div className='oppmap-legend-swatch' style={{ backgroundColor: l }} /><span>Low</span>
    </div>
  )
}
