import React from 'react'
import { IonSpinner } from '@ionic/react'
import './MapSpinner.css'

export default ({ spinnerVis, title, mapType }) => {
  let d1, d2

  if (mapType === 'landcover') {
    d1 = <div>Generating land cover map.</div>
  } else if (mapType === 'hedges') {
    d1 = <div>Generating hedgerow map.</div>
  } else if (title.startsWith('Select')) {
    d1 = <div>Generating opportunity map overlays.</div>
    d2 = (
      <div>
        Scaling map colours to
        reflect local priorities over your area. For larger
        areas it may take a little time.
      </div>
    )
  } else {
    d1 = <div>Generating opportunity map overlay for <b>{title}</b>.</div>
    d2 = (
      <div>
        Scaling map colours to
        reflect local priorities over your area. For larger
        areas it may take a little time.
      </div>
    )
  }

  return (
    <div className='spinnerDivOuter' style={{ display: spinnerVis }}>
      {d1}
      <div className='spinnerDivInner'><IonSpinner /></div>
      {d2}
    </div>
  )
}
