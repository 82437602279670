import React, { useState } from 'react'
import { ImageOverlay, GeoJSON, WMSTileLayer, Pane } from 'react-leaflet'
import Control from 'react-leaflet-control'
import OpMapBase from './OpMapBase'
import Legend from './Legend'
import NationalIndicator from './NationalIndicator'
import DataWarning from './DataWarning'
import './OpMapDisplay.css'

export default ({
  mapDetail,
  aoiGeoJson,
  gisGeoJson,
  overlayOpacity,
  fieldsOpacity,
  mapPosition,
  setMapPosition,
  priorities,
  mapElement,
  setMapElement,
  displayOpWms,
  spinnerVis,
  dataProbsFlag,
  basemap
}) => {
  const [errorMessage, setErrorMessage] = useState('')

  let imageoverlay = null

  // console.log('mapDetail.mapData', mapDetail.mapData)
  if (mapDetail.mapData) {
    const bbox = aoiGeoJson ? aoiGeoJson.bbox : gisGeoJson.bbox
    const bounds = [[bbox.ymin, bbox.xmin], [bbox.ymax, bbox.xmax]]
    imageoverlay = (
      <ImageOverlay url={mapDetail.mapData} bounds={bounds} opacity={overlayOpacity} />
    )
  }

  // Field boundary WMS is only displayed at zoom level 13 or above
  let fieldsWMS = null
  if (mapPosition.zoom >= 13) {
    fieldsWMS = (
      <WMSTileLayer
        url={process.env.REACT_APP_FIELDSMAP_HOST + 'cgi-bin/mapserv?map=' + process.env.REACT_APP_FIELDSMAP_FILE}
        opacity={fieldsOpacity}
        layers='Fields'
        format='image/png'
      />
    )
  }

  // Opportunity layer WMS
  let opwms
  if (displayOpWms) {
    opwms = (
      <WMSTileLayer
        url={process.env.REACT_APP_FIELDSMAP_HOST + 'cgi-bin/mapserv?map=' + process.env.REACT_APP_OPMAP_FILE}
        layers={mapDetail.wmsLayer}
        format='image/png'
      />
    )
  }

  return (
    <OpMapBase
      title={mapDetail.caption}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      setMapElement={setMapElement}
      mapElement={mapElement}
      mapPosition={mapPosition}
      setMapPosition={setMapPosition}
      spinnerVis={spinnerVis[mapDetail.id]}
      type={mapDetail.id}
      basemap={basemap}
    >
      <Pane className='aoiGjson'>
        <GeoJSON
          key={gisGeoJson ? gisGeoJson.name : null} data={gisGeoJson ? gisGeoJson.gjson : null}
          style={{
            color: '#000000',
            weight: 1,
            opacity: 1,
            fillOpacity: 0
          }}
        />
      </Pane>
      <Pane className='fieldWMS'>
        {fieldsWMS}
      </Pane>
      <Pane className='imageoverlay'>
        {imageoverlay}
      </Pane>
      <Pane className='opwms'>
        {opwms}
      </Pane>
      <Control position='bottomright'>
        <DataWarning dataProbsFlag={dataProbsFlag} />
      </Control>

      <Control position='bottomright'>
        <NationalIndicator priority={priorities && priorities[mapDetail.id] ? priorities[mapDetail.id][0] : null} />
      </Control>

      <Control position='bottomright'>
        <Legend lstyle={mapDetail.legendStyle} />
      </Control>

    </OpMapBase>
  )
}
