import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRouterLink } from '@ionic/react'
import React from 'react'
import Track from '../components/Track'
import NumberBox from '../components/NumberBox'
import './Page.css'
import Logo from '../images/e-planner_white.png'
import ExampleImage from '../images/example-image.PNG'
import './About.css'

export default () => {
  const step1 = (
    <NumberBox number='1' title='Make an assessment' colour='#4B94A4'>
      Consider which environmental opportunities you’re interested in.
      Use agronomic data (e.g. precision yield maps) and your own knowledge
      to identify less productive or difficult to farm areas.
    </NumberBox>
  )
  const step2 = (
    <NumberBox number='2' title='Use the tool' colour='#0282A4'>
      Compare suitability of different opportunities for each area or find the
      most suitable areas for each opportunity. Build an initial plan.
    </NumberBox>
  )
  const step3 = (
    <NumberBox number='3' title='Sense check' colour='#148082'>
      Use your field visits to check your plan. There may be local
      factors the maps don’t account for (e.g. infrastructure, disturbance).
    </NumberBox>
  )
  const step4 = (
    <NumberBox number='4' title='Implement and monitor' colour='#3C7854'>
      Use best practice guidance to implement management and
      monitor its success  (see <IonRouterLink routerLink='/NextSteps'>Next Steps</IonRouterLink>)
    </NumberBox>
  )
  return (
    <IonPage>
      <Track />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>About E-Planner</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className='text-page'>
          <p>
            <IonRouterLink routerLink='/app/Select' style={{ width: '250px', float: 'left' }}><img src={Logo} alt='E-planner logo' /></IonRouterLink>
            Welcome to E-Planner! E-Planner is a free tool developed by UKCEH to help farmers and other land mangers
            identify the most suitable places for different environmental management actions via easy to use, interactive maps.
          </p>
          <p>
            <IonRouterLink routerLink='/app/Select'>E-Planner</IonRouterLink> uses environmental datasets to build GB-coverage
            maps of the relative suitability of land for different environmental opportunities. Each opportunity has a range of
            associated potential management actions, depending on the farming system and local context, but with relative
            suitability driven by similar factors. The five opportunities currently mapped in E-Planner are:
          </p>
          <img src={ExampleImage} className='ion-hide-sm-down' style={{ float: 'right', width: '50%', margin: '0.5em 0 0.5em 1em' }} alt='Example opportunity map' />
          <img src={ExampleImage} className='ion-hide-sm-up' style={{ width: '100%', margin: '0.5em 0 0.5em 0' }} alt='Example opportunity map' />

          <ul id='help-list' style={{ clear: 'left' }}>
            <li>
              <b>Water resource protection</b> (e.g. buffer strips and cover crops)
            </li>
            <li>
              <b>Woodland creation</b> (e.g. planting of trees on-farm)
            </li>
            <li>
              <b>Seed-rich bird habitat</b> (e.g. wild bird seed margins, overwinter stubbles)
            </li>
            <li>
              <b>Flower-rich pollinator habitats</b> (e.g. flower margins and grassland restoration)
            </li>
            <li>
              <b>Wet grassland restoration</b> (e.g. restoring wet grassland and floodplain meadows)
            </li>
          </ul>

          <p>
            Suitability is based on factors such as topography, soils, nearby habitats, landscape
            features etc. Maps are presented as 5-metre resolution ‘heat maps’ for a chosen area or
            farm, making it straightforward to compare the most suitable opportunity for a given area
            or to identify the most suitable location for a particular opportunity.
          </p>
          <p>
            E-Planner supports and streamlines decision making by presenting complex environmental data
            in an easy to interpret way. It cannot replace local knowledge and does not suggest
            an ‘optimum’ solution, so we suggest using the following workflow:
          </p>

          <div style={{ clear: 'right' }}>
            <div className='grid-container ion-hide-sm-down'>{step1}{step2}</div>
            <div className='grid-container  ion-hide-sm-down'>{step3}{step4}</div>
            <div className='ion-hide-sm-up'>{step1}{step2}{step3}{step4}</div>
          </div>

          <p>
            Farmers are increasingly under pressure to deliver a wide range of environmental goals
            from their land as well as producing food. However, balancing these competing goals is
            a big challenge. Although there are many digital tools supporting the planning of
            fine-scale decisions around food production (e.g. precision agricultural systems),
            equivalents for supporting the environmental aspects of farm decision-making are limited.
            We designed E-Planner to fill this gap.
          </p>
          <p>
            We welcome feedback and suggestions for improvement; see
            the <IonRouterLink routerLink='/ukceh'>About UKCEH</IonRouterLink> section
            for contact details. A scientific paper detailing the science, technology
            and methods behind E-Planner is
            also <a target='_blank' rel='noopener noreferrer' href='https://www.sciencedirect.com/science/article/pii/S1364815222001438?via%3Dihub'>now available</a>.
          </p>
          <p>
            Now find out <IonRouterLink routerLink='/UserGuide'>how to use E-Planner</IonRouterLink>...
          </p>
        </div>
      </IonContent>
    </IonPage>
  )
}
