import React from 'react'
import LegendLcm from './LegendLcm'
import LegendHedges from './LegendHedges'

export default ({ mapDetail, legendAttr, setLegendAttr }) => {
  if (mapDetail.legendData) {
    let legend
    if (mapDetail.id === 'landcover') {
      legend = <LegendLcm mapDetail={mapDetail} />
    } else if (mapDetail.id === 'hedges') {
      legend = <LegendHedges mapDetail={mapDetail} legendAttr={legendAttr} setLegendAttr={setLegendAttr} />
    }
    return legend
  } else {
    // In legendData is empty, return null
    return null
  }
}
