import React from 'react'
import './Legend.css'

function showHideLegend () {
  const legend = document.getElementById('lcm-legend_items')
  const button = document.getElementById('lcm-legend-hide-show')
  if (legend.style.display === 'none') {
    legend.style.display = 'block'
    button.innerText = '[hide legend]'
  } else {
    legend.style.display = 'none'
    button.innerText = '[show legend]'
  }
}

export default ({ mapDetail }) => {
  const lcmCategories = {
    lcm_1: {
      legend: 'Broadleaved woodland',
      colour: 'rgb(255,0,0)'
    },
    lcm_2: {
      legend: 'Coniferous woodland',
      colour: 'rgb(0,102,0)'
    },
    lcm_3: {
      legend: 'Arable and Horticulture',
      colour: 'rgb(115,38,0)'
    },
    lcm_4: {
      legend: 'Improved Grassland',
      colour: 'rgb(0,255,0)'
    },
    lcm_5: {
      legend: 'Neutral Grassland',
      colour: 'rgb(127,229,127)'
    },
    lcm_6: {
      legend: 'Calcareous Grassland',
      colour: 'rgb(112,168,0)'
    },
    lcm_7: {
      legend: 'Acid Grassland',
      colour: 'rgb(153,129,0)'
    },
    lcm_8: {
      legend: 'Fen, Marsh and Swamp',
      colour: 'rgb(255,255,0)'
    },
    lcm_9: {
      legend: 'Heather',
      colour: 'rgb(128,26,128)'
    },
    lcm_10: {
      legend: 'Heather Grassland',
      colour: 'rgb(230,140,166)'
    },
    lcm_11: {
      legend: 'Bog',
      colour: 'rgb(0,128,115)'
    },
    lcm_12: {
      legend: 'Inland Rock',
      colour: 'rgb(210,210,255)'
    },
    lcm_13: {
      legend: 'Saltwater',
      colour: 'rgb(0,0,128)'
    },
    lcm_14: {
      legend: 'Freshwater',
      colour: 'rgb(0,0,255)'
    },
    lcm_15: {
      legend: 'Supralittoral Rock',
      colour: 'rgb(204,179,0)'
    },
    lcm_16: {
      legend: 'Supralittoral Sediment',
      colour: 'rgb(204,179,0)'
    },
    lcm_17: {
      legend: 'Littoral Rock',
      colour: 'rgb(255,255,128)'
    },
    lcm_18: {
      legend: 'Littoral Sediment',
      colour: 'rgb(255,255,128)'
    },
    lcm_19: {
      legend: 'Saltmarsh',
      colour: 'rgb(128,218,255)'
    },
    lcm_20: {
      legend: 'Urban',
      colour: 'rgb(0,0,0)'
    },
    lcm_21: {
      legend: 'Suburban',
      colour: 'rgb(128,128,128)'
    }
  }
  const lcmKeys = Object.keys(mapDetail.legendData.stats)
  // console.log('lcmKeys', lcmKeys)
  return (
    <div className='lcm-legend'>
      <div id='lcm-legend_items'>
        {lcmKeys.map(lcmKey => {
          if (lcmCategories[lcmKey]) {
            const area = Math.round(mapDetail.legendData.stats[lcmKey][0] / 10) / 10
            let sArea
            if (area) {
              sArea = `(${area} ha)`
            } else {
              sArea = '(<0.1 ha)'
            }
            return (
              <div key={lcmKey}>
                <div className='oppmap-legend-swatch' style={{ backgroundColor: lcmCategories[lcmKey].colour }} />
                <span>{lcmCategories[lcmKey].legend}</span>
                <span>&nbsp;{sArea}</span>
              </div>
            )
          } else {
            return <div key={lcmKey} />
          }
        })}
        <div style={{ marginLeft: '25px' }}>{mapDetail.legendData.footnote}</div>
      </div>
      <div id='lcm-legend-hide-show' onClick={showHideLegend}>[hide legend]</div>
    </div>
  )
}
