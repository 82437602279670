import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'

import './Page.css'

export default () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Acknowledgements</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className='text-page'>
          <p>
            E-Planner uses data from the following sources:
          </p>
          <ul>
            <li>Land Cover Map 2021, UK Centre for Ecology & Hydrology (© UKCEH)</li>
            <li>Natural England priority habitats inventory, Natural England  (© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right)</li>
            <li>NEXTMap elevation data, 5m digital surface model (DSM) of Great Britain, 5m digital terrain model (DTM) of Great Britain, Intermap Technologies (© Intermap Technologies 2007)</li>
            <li>NATMAP vector, Topsoil texture, Cranfield University (Soils Data © Cranfield University (NSRI) and for the Controller of HMSO 2020)</li>
            <li>MasterMap Topography Layer, Ordnance Survey (© Crown copyright and database rights 2020 OS 0100047213)</li>
          </ul>
          <p>
            E-planner is developed by the UK Centre for Ecology and Hydrology. John Redhead and Richard Pywell devised
            the tool, with development of the interface led by Rich Burkmar and Mike Brown. We would like to thank
            the many farmers, agronomists and others who have tested E-Planner and provided useful feedback, with
            particular thanks to Marek Nowakowski of the Wildlife Farming Company for his work on validating E-Planner
            in the field. We would also like to thank the Floodplain Meadows Partnership for their input on the wet
            grassland suitability maps.
          </p>
        </div>
      </IonContent>
    </IonPage>
  )
}
