import React, { useEffect, useRef, useState } from 'react'
import { IonContent, IonToast } from '@ionic/react'
import { Map, ZoomControl, TileLayer, ScaleControl } from 'react-leaflet'
import Control from 'react-leaflet-control'
import MapSpinner from './MapSpinner'

import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import './OpMapBase.css'

export default ({
  mapElement,
  setMapElement,
  mapPosition,
  setMapPosition,
  type,
  idPrefix,
  errorMessage,
  setErrorMessage,
  title,
  spinnerVis,
  setHelpDisplayed,
  children,
  basemap
}) => {
  const inputEl = useRef(null)
  const [baseHelpDisplayed, setBaseHelpDisplayed] = useState(type === 'select')

  const helpText = baseHelpDisplayed ? 'Hide help' : 'Show help'
  const helpButtonDisplayStyle = (type === 'select') ? '' : 'none'
  const helpDisplayStyle = baseHelpDisplayed ? '' : 'none'
  function baseToggleHelp () {
    const display = !baseHelpDisplayed
    setBaseHelpDisplayed(display)
    if (setHelpDisplayed) {
      setHelpDisplayed(display)
    }
  }

  useEffect(() => {
    if (!mapElement) {
      setMapElement(inputEl)
    }
    // If a new view has been set from a different map element, reflect that view
    // in this map element
    // This has to go inside useEffect hook so that map is updated after this element is
    // rendered. Otherwise it can trigger the 'Cannot update a component from inside the function body
    // a different component' error: https://github.com/facebook/react/issues/18178
    if (inputEl.current &&
        mapPosition.centre &&
        inputEl.current.leafletElement._leaflet_id !== mapPosition.mapId &&
        (mapPosition.centre.lat !== inputEl.current.leafletElement.getCenter().lat ||
          mapPosition.centre.lng !== inputEl.current.leafletElement.getCenter().lng ||
          mapPosition.zoom !== inputEl.current.leafletElement.getZoom())
    ) {
      // Very important to disable animation
      const NO_ANIMATION = {
        animate: false,
        reset: true
        // disableViewprereset: true
      }
      inputEl.current.leafletElement.setView(mapPosition.centre, mapPosition.zoom, NO_ANIMATION)
    }
  })

  const mapId = idPrefix + '_' + type + 'Map'

  function mapMoveEnd (e) {
    if (mapPosition.centre &&
      (mapPosition.centre.lat !== e.target.getCenter().lat ||
        mapPosition.centre.lng !== e.target.getCenter().lng ||
        mapPosition.zoom !== e.target.getZoom())
    ) {
      setMapPosition(
        {
          mapId: e.target._leaflet_id,
          zoom: e.target.getZoom(),
          centre: e.target.getCenter()
        }
      )
    }
  }

  let baseMapLayer
  if (basemap === 'satellite') {
    baseMapLayer =
      <TileLayer
        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      />
  } else {
    baseMapLayer =
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors. Use of data is subject to <a style="cursor: pointer" onclick="termsAndConditions()">terms and conditions</a>.'
      />
  }

  return (
    <IonContent>
      <IonToast
        id='ionToast'
        isOpen={errorMessage !== ''}
        onDidDismiss={() => setErrorMessage('')}
        message={errorMessage}
        duration={3000}
        position='middle'
      />
      <Map
        id={mapId} ref={inputEl}
        zoomControl={false}
        attributionControl
        // Centre and zoom to show whole of GB
        center={{ lat: 52.5, lng: -1.5 }}
        zoom={8}
        // For some reason, using the method below caused React to recursively render
        // center={[mapPosition.centre.lat, mapPosition.centre.lng]}
        // zoom={mapPosition.zoom}
        onMoveEnd={e => mapMoveEnd(e)}
        onClick={e => console.log('click', e.latlng)}
      >
        {/* Basemap layer */}
        {baseMapLayer}

        {/* <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors. Use of data is subject to <a style="cursor: pointer" onclick="termsAndConditions()">terms and conditions</a>.'
        />
        <TileLayer
          url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
          attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        /> */}

        {/* Ordnance Survey API */}
        {/* <TileLayer
          url={`https://api2.ordnancesurvey.co.uk/mapping_api/v1/service/zxy/EPSG%3A3857/Outdoor 3857/{z}/{x}/{y}.png?key=${apiKey}`}
        /> */}

        <Control position='topleft'>
          <div className='map-type'>{title}</div>
        </Control>

        <Control position='topleft' className='ctlGetLayers'>
          <div className='leaflet-bar' style={{ display: helpButtonDisplayStyle }}>
            <button title='Show/hide control help' onClick={e => baseToggleHelp(e)}>{helpText}</button>
          </div>
        </Control>

        <ZoomControl position='topright' />
        <Control position='topright' className='ctlMapHelpControl'>
          <div className='ctlMapHelp' style={{ display: helpDisplayStyle }}>
            Zoom in/out
          </div>
        </Control>
        <MapSpinner spinnerVis={spinnerVis} title={title} mapType={type} />

        <ScaleControl position='bottomleft' />
        {children}
      </Map>
    </IonContent>
  )
}
