import { IonContent, IonPage } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import OpMapDisplay from '../components/OpMapDisplay'
import InvMapDisplay from '../components/InvMapDisplay'
import invalidateMap from './leaflet-workaround.js'
import Track from '../components/Track'
import './All.css'

export default ({
  type,
  mapDetails,
  setMapPosition,
  mapPosition,
  spinnerVis,
  gisGeoJson,
  aoiGeoJson,
  overlayOpacity,
  fieldsOpacity,
  displayOpWms,
  priorities,
  dataProbsFlag,
  basemap
}) => {
  const mapElements = {}
  const setMapElements = {}

  // console.log('map type', type)

  // Can't use useState in loops - results undefined
  // mapDetails.forEach(m => {
  //   [mapElements[m.id], setMapElements[m.id]] = useState(null)
  // })

  // Opportunity maps
  const [m1, sm1] = useState(null)
  const [m2, sm2] = useState(null)
  const [m3, sm3] = useState(null)
  const [m4, sm4] = useState(null)
  const [m5, sm5] = useState(null)
  mapElements.woodland = m1
  setMapElements.woodland = sm1
  mapElements.runoff = m2
  setMapElements.runoff = sm2
  mapElements.birds = m3
  setMapElements.birds = sm3
  mapElements.pollinators = m4
  setMapElements.pollinators = sm4
  mapElements.wetgrass = m5
  setMapElements.wetgrass = sm5

  // Inventory maps
  const [m6, sm6] = useState(null)
  const [m7, sm7] = useState(null)
  mapElements.landcover = m6
  setMapElements.landcover = sm6
  mapElements.hedges = m7
  setMapElements.hedges = sm7

  const [displayedMaps, setDisplayedMaps] = useState('')

  // mapDetails.filter(m => m.type === 'op').forEach(m => {
  mapDetails.filter(m => m.type === type).forEach(m => {
    if (!m.checked && mapElements[m.id]) {
      setMapElements[m.id](null)
    }
    // Workaround - see leaflet-workaround.js for explanation
    invalidateMap(mapElements[m.id])
  })

  // const checkedMaps = mapDetails.filter(m => m.type === 'op').filter(m => m.checked)
  const checkedMaps = mapDetails.filter(m => m.type === type).filter(m => m.checked)
  const history = useHistory()

  useEffect(() => {
    // If displayed maps have been changed (type, number or order)
    // then they can be moved/resized on layout and Leaflet map
    // may not redraw/position correctly unless invalidateSize
    // method called. It's necessary to perform this using a
    // timeout to give Leaflet a chance to sort itself out.
    const newDisplayedMaps = checkedMaps.map(m => m.id).join()
    if (newDisplayedMaps !== displayedMaps) {
      checkedMaps.forEach(m => {
        setTimeout(() => {
          if (mapElements[m.id] && mapElements[m.id].current) {
            mapElements[m.id].current.leafletElement.invalidateSize()
          }
        }, 200)
      })
      setDisplayedMaps(newDisplayedMaps)
    }

    window.onresize = () => {
      // Changes away from /app/All or app/Inventory if display changes to an inappropriate size
      // for that page.
      // Ionic breakpoint values - https://ionicframework.com/docs/layout/grid
      const md = 768
      if (window.innerWidth < md) {
        if (window.location.pathname === '/app/All' || window.location.pathname === '/app/Inventory') {
          history.push('/app/Select')
        }
      }
    }
    // Cleanup - remove on resize handler
    return () => {
      window.onresize = null
    }
  }, [checkedMaps, displayedMaps, mapElements, history])

  if (type === 'op') { // opportunity map
    return (
      <IonPage>
        <Track />
        <IonContent>
          {checkedMaps.map((m, i) => {
            return (
              <div
                key={m.id}
                className={getMapDivClass(i, checkedMaps)}
              >
                {/* <TestControl type={type} map={m} /> */}
                <OpMapDisplay
                  idPrefix='multi'
                  mapDetail={m}
                  mapElement={mapElements[m.id]}
                  setMapElement={setMapElements[m.id]}
                  mapPosition={mapPosition}
                  setMapPosition={setMapPosition}
                  spinnerVis={spinnerVis}
                  gisGeoJson={gisGeoJson}
                  aoiGeoJson={aoiGeoJson}
                  overlayOpacity={overlayOpacity}
                  fieldsOpacity={fieldsOpacity}
                  displayOpWms={displayOpWms}
                  priorities={priorities}
                  dataProbsFlag={dataProbsFlag}
                  basemap={basemap}
                />
              </div>
            )
          })}
        </IonContent>
      </IonPage>
    )
  } else { // type === inv - inventory map
    return (
      <IonPage>
        <Track />
        <IonContent>
          {checkedMaps.map((m, i) => {
            return (
              <div
                key={m.id}
                className={getMapDivClass(i, checkedMaps)}
              >
                <InvMapDisplay
                  idPrefix='multi'
                  mapDetail={m}
                  mapElement={mapElements[m.id]}
                  setMapElement={setMapElements[m.id]}
                  mapPosition={mapPosition}
                  setMapPosition={setMapPosition}
                  spinnerVis={spinnerVis}
                  gisGeoJson={gisGeoJson}
                  aoiGeoJson={aoiGeoJson}
                  overlayOpacity={overlayOpacity}
                  fieldsOpacity={fieldsOpacity}
                  displayOpWms={displayOpWms}
                  priorities={priorities}
                  dataProbsFlag={dataProbsFlag}
                  basemap={basemap}
                />
              </div>
            )
          })}
        </IonContent>
      </IonPage>
    )
  }
}

function getMapDivClass (i, checkedMaps) {
  switch (checkedMaps.length) {
    case 1:
      return 'q1'
    case 2:
      return 'q2v'
    case 3:
      return i === 0 ? 'q2v' : 'q4'
    default:
      return 'q4'
  }
}
