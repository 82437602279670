import React from 'react'
import { IonIcon, IonList, IonListHeader } from '@ionic/react'
import { addOutline, removeOutline } from 'ionicons/icons'
import './OptsList.css'

export default ({
  id,
  header,
  fontWeight = 'normal',
  children,
  visOpts,
  setVisOpts
}) => {
  function showHideOpts () {
    if (visOpts === id) {
      setVisOpts('')
    } else {
      setVisOpts(id)
    }
  }

  function getDisplay () {
    return id === visOpts ? 'block' : 'none'
  }

  function getIcon () {
    return id === visOpts ? removeOutline : addOutline
  }

  return (
    <IonList className='controls-list'>
      <IonListHeader onClick={e => showHideOpts(e)}>
        <IonIcon icon={getIcon()} />
        <span style={{ fontWeight: fontWeight }}>{header}</span>
      </IonListHeader>
      <div id={id} style={{ display: getDisplay() }}>
        {children}
      </div>
    </IonList>
  )
}
