import React from 'react'
import './UserGuideTable.css'

export default () => {
  return (
    <table className='userGuideTable' border='0' cellSpacing='0' cellPadding='0'>
      <tbody>
        <tr className='userGuideTableHeader'>
          <td valign='top'><p><strong>Environmental opportunity</strong></p></td>
          <td valign='top'><p><strong>Factors affecting suitability</strong></p></td>
          <td valign='top'><p><strong>Example management options</strong></p></td>
        </tr>
        <tr className='userGuideTableRowOdd'>
          <td valign='top'>
            <p>Flower-rich pollinator habitat</p>
          </td>
          <td valign='top'>
            <ul>
              <li>South facing slopes</li>
              <li>Light, high pH soils</li>
              <li>Out of shade or wet-lying areas</li>
              <li>Connect up existing habitats</li>
            </ul>
          </td>
          <td valign='top'>
            <ul>
              <li>Sow annual pollen and nectar mixes </li>
              <li>Establish perennial wildflower areas</li>
              <li>Restore botanically species-rich grassland</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td valign='top'>
            <p>Seed-rich bird habitat</p>
          </td>
          <td valign='top'>
            <ul>
              <li>South facing slopes</li>
              <li>Out of shade or wet-lying areas</li>
              <li>Away from watercourses</li>
              <li>Close to cover</li>
            </ul>
          </td>
          <td valign='top'>
            <ul>
              <li>Sow wild bird seed mixes</li>
              <li>Unharvested conservation headlands</li>
              <li>Overwinter stubbles</li>
            </ul>
          </td>
        </tr>
        <tr className='userGuideTableRowOdd'>
          <td valign='top'>
            <p>Water resource protection</p>
          </td>
          <td valign='top'>
            <ul>
              <li>Close to watercourses</li>
              <li>Steeper slopes</li>
              <li>Highly erodible soils</li>
            </ul>
          </td>
          <td valign='top'>
            <ul>
              <li>Grass buffer strips</li>
              <li>Soil management</li>
              <li>Cover crops</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td valign='top'>
            <p>Wet grassland restoration</p>
          </td>
          <td valign='top'>
            <ul>
              <li>High risk of river flooding</li>
              <li>Wet-lying areas</li>
              <li>Connect up existing wet grasslands</li>
              <li>Soils suitable</li>
            </ul>
          </td>
          <td valign='top'>
            <ul>
              <li>Cutting/grazing regimes</li>
              <li>Manage drainage </li>
              <li>Reseeding suitable wildflowers</li>
            </ul>
          </td>
        </tr>
        <tr className='userGuideTableRowOdd'>
          <td valign='top'>
            <p>Woodland creation</p>
          </td>
          <td valign='top'>
            <ul>
              <li>Connect up existing woodland</li>
              <li>Moderate slopes</li>
              <li>Highly erodible soils</li>
              <li>Non-peat soils</li>
            </ul>
          </td>
          <td valign='top'>
            <ul>
              <li>Plant native trees on-farm</li>
              <li>Protect in-hedge or in-field trees</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
