import React from 'react'
import { IonRouterLink } from '@ionic/react'
import './DataWarning.css'

export default ({ dataProbsFlag }) => {
  const dataWarningDisplay = ''

  if (dataProbsFlag) {
    return (
      <div className='data-warning' style={{ display: dataWarningDisplay }}>
        <div>
          There is uncertainty associated with some of the data used
          to generate this map. <IonRouterLink color='light' routerLink='/app/DataUncertainty'>More information</IonRouterLink>
        </div>
      </div>
    )
  } else {
    return null
  }
}
