import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'
import Track from '../components/Track'

import './Page.css'

export default () => {
  return (
    <IonPage>
      <Track />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Next Steps</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className='text-page'>
        <div className='text-page'>
          <p>
            If you want to know more about how to implement the environmental actions for
            which E-Planner maps suitability, there is a wide variety of guidance available
            online. The Farm Wildlife partnership
            “<a target='_blank' rel='noopener noreferrer' href='https://farmwildlife.info/how-to-do-it/'>How to do it</a>” pages
            cover many of the
            management options relevant to the E-Planner opportunity maps. See below for
            some additional (not exhaustive) resources:
          </p>
          <h4>Water resource protection</h4>
          <p>
            Buffer strips of perennial grasses help to reduce runoff or pollutants such as pesticides, fertilisers
            or sediment from agricultural fields into watercourses:<br />
            <a target='_blank' rel='noopener noreferrer' href='https://farmwildlife.info/how-to-do-it-5/field-boundaries/grass-margins-as-buffer-strips/'>https://farmwildlife.info/how-to-do-it-5/field-boundaries/grass-margins-as-buffer-strips/</a><br />
          </p>
          <p>
            Cover crops can also help reduce pollutant runoff and there are many guides available:<br />
            <a target='_blank' rel='noopener noreferrer' href='https://ahdb.org.uk/cover-crops'>https://ahdb.org.uk/cover-crops</a><br />
            <a target='_blank' rel='noopener noreferrer' href='https://farmwildlife.info/how-to-do-it/farmed-area/winter-cover-crops-and-green-manures/'>https://farmwildlife.info/how-to-do-it/farmed-area/winter-cover-crops-and-green-manures/</a>
          </p>

          <h4>Woodland creation</h4>
          <p>
            The Woodland Trust produce a variety of resources for tree planting:<br />
            <a target='_blank' rel='noopener noreferrer' href='https://www.woodlandtrust.org.uk/plant-trees/large-scale-planting/'>
              https://www.woodlandtrust.org.uk/plant-trees/large-scale-planting/
            </a>
          </p>
          <p>
            Farmers Weekly have
            also produce an introductory guide to planting trees on
            farms:<br />
            <a target='_blank' rel='noopener noreferrer' href='https://www.fwi.co.uk/business/business-management/ultimate-guide-how-to-plant-trees-on-your-farm'>
              https://www.fwi.co.uk/business/business-management/ultimate-guide-how-to-plant-trees-on-your-farmour-farm
            </a><br />
          </p>
          <p>
            See also information from Government schemes available that may provide sources of funding for woodland creation:<br />
            England:&nbsp;
            <a target='_blank' rel='noopener noreferrer' href='https://www.gov.uk/guidance/england-woodland-creation-offer'>
              https://www.gov.uk/guidance/england-woodland-creation-offer
            </a><br />
            Scotland:&nbsp;
            <a target='_blank' rel='noopener noreferrer' href='https://forestry.gov.scot/support-regulations/forestry-grants'>
              https://forestry.gov.scot/support-regulations/forestry-grants
            </a><br />
            Wales:&nbsp;
            <a target='_blank' rel='noopener noreferrer' href='https://naturalresources.wales/guidance-and-advice/environmental-topics/woodlands-and-forests/?lang=en'>
              Natural Resources Wales / Woodlands and forests
            </a>
          </p>
          <h4>Seed-rich bird habitat</h4>
          <p>
            The Farm Wildlife partnership have produced guidance on creating seed-rich habitats,
            including creating seed rich habitats, available here:<br />
            <a target='_blank' rel='noopener noreferrer' href='https://farmwildlife.info/how-to-do-it-5/seed-rich-habitats/'>
              https://farmwildlife.info/how-to-do-it-5/seed-rich-habitats/
            </a>
          </p>

          <h4>Flower-rich pollinator habitats</h4>
          <p>
            UKCEH and the Wildlife Farming Company have published a guide to Habitat Creation
            and Management for Pollinators. This is free to download here:<br />
            <a target='_blank' rel='noopener noreferrer' href='https://www.ceh.ac.uk/book-habitat-creation-and-management-pollinators'>
              https://www.ceh.ac.uk/book-habitat-creation-and-management-pollinators
            </a>
          </p>
          <p>
            The Farm Wildlife partnership also has guidance on flower-rich habitats:<br />
            <a target='_blank' rel='noopener noreferrer' href='https://farmwildlife.info/how-to-do-it/flower-rich-habitats'>
              https://farmwildlife.info/how-to-do-it/flower-rich-habitats
            </a>
          </p>
          <h4>Wet grassland habitats</h4>
          <p>
            Wet grasslands provide important habitats for wild flowers, insects, waders and wildfowl.
            They can also help to store carbon and reduce the risk of downstream flooding and soil erosion.
            There are several wet grassland types, ranging from wet pastures that provide habitat for
            wading birds to botanically species-rich floodplain meadows. Different types need different
            approaches to drainage and management, so it is important to consider what type of wet
            grassland is most suited to your area. The Floodplain Meadows Partnership has a great
            deal of information on how to restore and manage wet grassland habitats.<br />
            <a target='_blank' rel='noopener noreferrer' href='http://www.floodplainmeadows.org.uk'>
              http://www.floodplainmeadows.org.uk
            </a><br />
            The RSPB have information on management of water levels on grassland for wildlife:<br />
            <a target='_blank' rel='noopener noreferrer' href='https://www.rspb.org.uk/our-work/conservation/conservation-and-sustainability/farming/advice/techniques-to-help-wildlife/re-wetting-grassland'>
              https://www.rspb.org.uk/our-work/conservation/conservation-and-sustainability/farming/advice/techniques-to-help-wildlife/re-wetting-grassland
            </a>
          </p>
          <h4>Agri-environment schemes</h4>
          <p>
            For all of the above, see also the current guidance on GB’s various agri-environment
            schemes, which include opportunities for grants and subsidies for environmental
            management actions.<br />
            England:&nbsp;
            <a target='_blank' rel='noopener noreferrer' href='https://www.gov.uk/countryside-stewardship-grants'>
              https://www.gov.uk/countryside-stewardship-grants
            </a><br />
            England:&nbsp;
            <a target='_blank' rel='noopener noreferrer' href='https://www.gov.uk/government/publications/sfi-handbook-for-the-sfi-2023-offer'>
              https://www.gov.uk/government/publications/sfi-handbook-for-the-sfi-2023-offer
            </a><br />
            Scotland:&nbsp;
            <a target='_blank' rel='noopener noreferrer' href='https://www.ruralpayments.org/topics/all-schemes/'>
              https://www.ruralpayments.org/topics/all-schemes/
            </a><br />
            Wales:&nbsp;
            <a target='_blank' rel='noopener noreferrer' href=' https://gov.wales/rural-grants-payments'>
              https://gov.wales/rural-grants-payments
            </a>
          </p>
          <h4>Monitoring</h4>
          <p>
            UKCEH has developed a free mobile app (E-Surveyor) to help monitor environmental options.
            E-Surveyor allows automated identification of plants from photos, allowing users to check
            what’s been seen against what’s been sown or against what would be expected in the target
            habitat. The app also provides information on the number of pollinators and natural
            enemies of crop pests known to benefit from the plants in your habitats. Find out more at:<br />
            <a target='_blank' rel='noopener noreferrer' href='https://esurveyor.ceh.ac.uk/'>https://esurveyor.ceh.ac.uk/</a>.
          </p>
        </div>
      </IonContent>
    </IonPage>
  )
}
