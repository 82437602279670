import React, { useCallback } from 'react'
import './Legend.css'

function showHideLegend () {
  const legend = document.getElementById('hedges-legend_items')
  const button = document.getElementById('hedges-legend-hide-show')
  if (legend.style.display === 'none') {
    legend.style.display = 'block'
    button.innerText = '[hide legend]'
  } else {
    legend.style.display = 'none'
    button.innerText = '[show legend]'
  }
}

export const heightCategories = {

  '1a': {
    legend: '0.5-0.99m',
    colour: '#A52A2A'
  },
  '1b': {
    legend: '1-1.49m',
    colour: '#9E6B4C'
  },
  '1c': {
    legend: '1.5-1.99m',
    colour: '#97AC6E'
  },
  2: {
    legend: '2-2.99m',
    colour: '#90EE90'
  },
  3: {
    legend: '3-3.99m',
    colour: '#60C060'
  },
  4: {
    legend: '4-5.99m',
    colour: '#309230'
  },
  6: {
    legend: '6m and over',
    colour: '#006400'
  }
}

export const typeCategories = {
  w: {
    legend: 'Probable wide single hedge',
    colour: 'blue'
  },
  d: {
    legend: 'Double hedge',
    colour: 'magenta'
  },
  s: {
    legend: 'Single hedge',
    colour: 'green'
  }
}

export const getStyleHedgesFunction = (legendAttr, overlayOpacity) => {
  return (feature) => {
    const attrCategories = legendAttr === 'hghtcls' ? heightCategories : typeCategories
    const categories = Object.keys(attrCategories)
    for (let i = 0; i < categories.length; i++) {
      if (categories[i] === feature[legendAttr]) {
        return { color: attrCategories[categories[i]].colour, opacity: overlayOpacity, weight: 5 }
      }
    }
  }
}

export default ({ mapDetail, legendAttr, setLegendAttr }) => {
  const toggleDisplayType = useCallback(() => {
    // Have to use callback hook rather than straight function here
    // because of a linting rule to avoid functions defined in
    // the body of the component. Needs to be in the compoment
    // because it uses uing useState hook passed in by calling
    // compoment.
    if (legendAttr === 'hghtcls') {
      setLegendAttr('label')
    } else {
      setLegendAttr('hghtcls')
    }
  }, [legendAttr])

  const sumprop = legendAttr === 'hghtcls' ? 'hght_sum' : 'type_sum'
  const legendKeys = Object.keys(mapDetail.legendData.stats[sumprop]).sort()
  const attrCategories = legendAttr === 'hghtcls' ? heightCategories : typeCategories

  return (
    <div className='hedges-legend'>
      <div id='hedges-legend_items'>
        {legendKeys.map(legendKey => {
          if (attrCategories[legendKey]) {
            const length = Math.round(mapDetail.legendData.stats[sumprop][legendKey][0])
            const sLength = `(${length} m)`
            return (
              <div key={legendKey}>
                <div className='oppmap-legend-swatch' style={{ backgroundColor: attrCategories[legendKey].colour }} />
                <span>{attrCategories[legendKey].legend}</span>
                <span>&nbsp;{sLength}</span>
              </div>
            )
          } else {
            return <div key={legendKey} />
          }
        })}
        {/* <div style={{ marginLeft: '25px' }}>{mapDetail.legendData.footnote}</div> */}
        {/* onClick={toggleDisplayType} */}
        {/* {legendAttr === 'hghtcls' ? 'show types' : 'show heights'} */}
        <div id='hedges-legend-display-toggle' onClick={toggleDisplayType}>[{legendAttr === 'hghtcls' ? 'show types' : 'show heights'}]</div>
      </div>
      <div id='hedges-legend-hide-show' onClick={showHideLegend}>[hide legend]</div>
    </div>
  )
}
