import React from 'react'

export default ({ maxWidth, colour, number, title, children }) => {
  const aMaxWidth = maxWidth || '500px'
  return (
    <div style={{ border: '2px solid black', borderRadius: '10px', padding: '0.5em', maxWidth: aMaxWidth, margin: '0.25em 0' }}>
      <table>
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'top' }}>
              <div style={{ fontSize: '400%', fontWeight: 'bold', marginRight: '0.2em', color: colour, maxWidth: '300px' }}>
                {number}
              </div>
            </td>
            <td>
              <div style={{ fontWeight: 'bold', marginBottom: '0.5em', fontSize: '0.9em' }}>
                {title}
              </div>
              <div style={{ fontSize: '0.9em' }}>
                {children}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
