import React, { useState, useRef, useEffect } from 'react'
import { IonPopover, IonButton, IonInput } from '@ionic/react'
import { FeatureGroup, GeoJSON } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import { useHistory } from 'react-router-dom'
import Control from 'react-leaflet-control'
import OpMapBase from './OpMapBase'
import testArea from '../resources/test-area.json'
import { FilePicker } from 'react-file-picker'
import { IoLocationOutline, IoTrashOutline, IoColorWandOutline } from 'react-icons/io5'
import proj4 from 'proj4'
import { reproject } from 'reproject'
import L from 'leaflet'
import ReactGA from './ReactGAProxy.js'
import st from 'geojson-bounds'
import { getGrFromCoords } from 'brc-atlas-bigr'
import { csv } from 'd3-fetch'
import dataProbsFile from '../resources/problem-hectads.csv'
import './OpMapAoi.css'

// RPA API
// https://environment.data.gov.uk/rpa/api-doc/
const rpaApi = 'https://environment.data.gov.uk/data-services/RPA/LandParcels/wfs?version=2.0.0&request=GetFeature&typeNames=RPA:LandParcels&srsname=EPSG:4326&outputFormat=application/json&cql_filter=SBI='
// Need to use a new proxy to the changed RPA API (updated June 2024) because the cross origin headers are wrong on API:
// The 'Access-Control-Allow-Origin' header contains multiple values '*, http://localhost:3000'
// We should be able to get round it by going via an nginx reverse proxy, but we couldn't get this to work
// when depolyed to K8s clusters.
// const rpaApi = `${process.env.REACT_APP_RPA_PROXY}data-services/RPA/LandParcels/wfs?version=2.0.0&request=GetFeature&typeNames=RPA:LandParcels&srsname=EPSG:4326&outputFormat=application/json&cql_filter=SBI=`
// const rpaApi = `${process.env.REACT_APP_RPA_PROXY}rpaproxy?sbi=`
// So instead using a proxy made from a node/koa service.
// Defra fixed header problem 11/06/2023

// Constants for maximum AOI area
const maxAoiArea = 25000000
const maxAoiAreaMessage = 'The area of interest you specified is too large - it must be less than 25 square kilometres (2,500 hectares).'

// Add BNG to proj4 projections
proj4.defs('epsg:27700', '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs')

const shapefile = require('shapefile')

export default ({
  spinnerVis,
  setGisGeoJson,
  gisGeoJson,
  setAoiGeoJson,
  aoiGeoJson,
  setMapElement,
  mapElement,
  setMapPosition,
  mapPosition,
  setDataProbsFlag,
  basemap,
  setMapDetails,
  mapDetails,
  updateMapsCallback,
  lastMapType
}) => {
  const history = useHistory()
  const [showRpaPopover, setShowRpaPopover] = useState(false)
  const [rpaSbi, setRpaSbi] = useState('')
  const [inputRpaSbi, setInputRpaSbi] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [helpDisplayed, setHelpDisplayed] = useState(true)
  const [dataProbs, setdataProbs] = useState([])
  const [dataProbsFileRead, setDataProbsFileRead] = useState(false)
  const drawnFeatures = useRef(null)

  useEffect(() => {
    L.drawLocal.draw.toolbar.buttons.rectangle = 'Drag a rectangle for area of interest'

    // Set the object that stores hectad references of problem data tiles
    if (!dataProbsFileRead) {
      csv(dataProbsFile).then(data => {
        setDataProbsFileRead(true)
        setdataProbs(data.map(o => o.GridRef))
      })
    }
  })

  let spinnerVisAny = 'none'
  if (!spinnerVis.birds || !spinnerVis.birds || !spinnerVis.birds || !spinnerVis.birds) {
    // At least one map has spinner displayed (vis = '')
    spinnerVisAny = ''
  }

  // If GIS boundaries selected, delete AOI features
  if (gisGeoJson && drawnFeatures.current) {
    const ftrs = drawnFeatures.current.leafletElement.getLayers()
    ftrs.forEach(function (ftr) {
      drawnFeatures.current.leafletElement.removeLayer(ftr)
    })
  }

  function callGetLayers () {
    if (aoiGeoJson || gisGeoJson) {
      // Zoom map to extent of aoi
      const bbox = aoiGeoJson ? aoiGeoJson.bbox : gisGeoJson.bbox
      mapElement.current.leafletElement.fitBounds([[bbox.ymin, bbox.xmin], [bbox.ymax, bbox.xmax]])

      // Limit extents of AOI
      const llBNG = proj4(proj4.WGS84, proj4('epsg:27700'), [bbox.xmin, bbox.ymin])
      const urBNG = proj4(proj4.WGS84, proj4('epsg:27700'), [bbox.xmax, bbox.ymax])
      const area = (urBNG[0] - llBNG[0]) * (urBNG[1] - llBNG[1])
      if (area > maxAoiArea) {
        setErrorMessage(maxAoiAreaMessage)
        return
      }

      // Identify if the AOI occurs in a problem hectads
      const llh = getGrFromCoords(bbox.xmin, bbox.ymin, 'wg', 'gb', [10000]).p10000
      const lrh = getGrFromCoords(bbox.xmax, bbox.ymin, 'wg', 'gb', [10000]).p10000
      const urh = getGrFromCoords(bbox.xmax, bbox.ymax, 'wg', 'gb', [10000]).p10000
      const ulh = getGrFromCoords(bbox.xmin, bbox.ymax, 'wg', 'gb', [10000]).p10000
      setDataProbsFlag(dataProbs.includes(llh) || dataProbs.includes(lrh) || dataProbs.includes(urh) || dataProbs.includes(ulh))

      ReactGA.event({
        category: 'ImageAPI',
        action: 'AOI specified (ha)',
        value: Math.round(area / 10000)
      })

      // Select tab
      const md = 768 // Threshold at which switch to single map display
      if (lastMapType === 'op') {
        if (window.innerWidth > md) {
          history.push('/app/All')
        } else {
          // First checked map in map details
          const opMap = mapDetails.filter(md => md.type === 'op').find(md => md.checked)
          history.push(`/app/${opMap.route}`)
        }
        updateMapsCallback('op')
      } else {
        if (window.innerWidth > md) {
          history.push('/app/Inventory')
        } else {
          // First checked map in map details
          const invMap = mapDetails.filter(md => md.type === 'inv').find(md => md.checked)
          history.push(`/app/${invMap.route}`)
        }

        history.push('/app/Inventory')
        updateMapsCallback('inv')
      }
    } else {
      setErrorMessage('Specify an area of interest first.')
    }
  }

  function deleteAoi () {
    const ftrs = drawnFeatures.current.leafletElement.getLayers()
    if (ftrs.length === 1) {
      drawnFeatures.current.leafletElement.removeLayer(ftrs[0])
    }
    // Reset init and mapData on mapDetails
    setMapDetails(currentMapDetails => {
      const newMapDetails = currentMapDetails.map(md => {
        const nmd = { ...md }
        nmd.mapData = null
        return nmd
      })
      return newMapDetails
    })
    setGisGeoJson(null)
    setAoiGeoJson(null)
  }

  function centreOnLocation () {
    mapElement.current.leafletElement.locate({ setView: true, maxZoom: 16, enableHighAccuracy: true })
  }

  function pReadAsArrayBuffer (inputFile) {
    // Returns a promise that resolves to
    // an array buffer for the file passed in.
    const tmpFileReader = new FileReader()
    return new Promise((resolve, reject) => {
      tmpFileReader.onerror = () => {
        tmpFileReader.abort()
        reject(new DOMException('Problem parsing input file.'))
      }
      tmpFileReader.onload = () => {
        resolve(tmpFileReader.result)
      }
      tmpFileReader.readAsArrayBuffer(inputFile)
    })
  }

  function setGeojsonFromGis (gjson) {
    const gisGjson = {
      name: Math.ceil(Math.random() * 10000),
      gjson: gjson,
      bbox: {
        xmin: gjson.bbox[0],
        xmax: gjson.bbox[2],
        ymin: gjson.bbox[1],
        ymax: gjson.bbox[3]
      }
    }

    // Reset init and mapData on mapDetals
    setMapDetails(currentMapDetails => {
      const newMapDetails = currentMapDetails.map(md => {
        const nmd = { ...md }
        nmd.mapData = null
        return nmd
      })
      return newMapDetails
    })
    setGisGeoJson(gisGjson)
    setAoiGeoJson(null)
    // Zoom map to extent of aoi
    const bbox = gisGjson.bbox
    mapElement.current.leafletElement.fitBounds([[bbox.ymin, bbox.xmin], [bbox.ymax, bbox.xmax]])
  }

  async function shpSelected (file) {
    ReactGA.event({
      category: 'Shapefile',
      action: 'Load shapefile requested'
    })

    const url = await pReadAsArrayBuffer(file)
    shapefile.read(url)
      .then((gjson) => {
        if (gjson.bbox[0] > 180 && gjson.bbox[2] > 180) {
        // Assume BNG and convert to WGS84
          gjson = reproject(gjson, proj4('epsg:27700'), proj4.WGS84, {})
        }
        setGeojsonFromGis(gjson)
      })
      .catch(error => {
        console.error(error.stack)
        ReactGA.event({
          category: 'Shapefile',
          action: 'Load shapefile failed',
          nonInteraction: true
        })
      })
  }

  function sbiSelected () {
    setShowRpaPopover(false)
    if (inputRpaSbi && inputRpaSbi !== rpaSbi) {
      ReactGA.event({
        category: 'RPA',
        action: 'RPA SBI entered'
      })
      setRpaSbi(inputRpaSbi)
      fetch(`${rpaApi}${inputRpaSbi}`)
        .then((response) => {
          console.log(response)
          if (response.status !== 200) {
            ReactGA.event({
              category: 'RPA',
              action: 'RPA API non-200 response status',
              nonInteraction: true
            })
            return
          }
          response.json().then(function (gjson) {
            if (gjson.error) {
              console.log(gjson.error)
              ReactGA.event({
                category: 'RPA',
                action: 'RPA API did not return valid JSON',
                nonInteraction: true
              })
              return
            }
            gjson.bbox = st.extent(gjson)
            setGeojsonFromGis(gjson)
          })
        })
        .catch(error => {
          console.error(error.stack)
          ReactGA.event({
            category: 'RPA',
            action: 'RPA API fetch errored',
            nonInteraction: true
          })
        })
    }
  }

  function onCreate (e) {
    // Limit to a single feature
    const ftrs = drawnFeatures.current.leafletElement.getLayers()
    if (ftrs.length > 1) {
      drawnFeatures.current.leafletElement.removeLayer(ftrs[0])
    }
    const ftr = ftrs[ftrs.length - 1] // Cannot use ftrs[0] here because removeLayer appears to be async
    const bnds = ftr.getBounds()
    // Reset init and mapData on mapDetals
    setMapDetails(currentMapDetails => {
      const newMapDetails = currentMapDetails.map(md => {
        const nmd = { ...md }
        nmd.mapData = null
        return nmd
      })
      return newMapDetails
    })
    setGisGeoJson(null)
    setAoiGeoJson({
      bbox: {
        xmin: bnds._southWest.lng,
        xmax: bnds._northEast.lng,
        ymin: bnds._southWest.lat,
        ymax: bnds._northEast.lat
      },
      gjson: ftr.toGeoJSON()
    })
  }

  function onDelete (e) {
    const ftrs = drawnFeatures.current.leafletElement.getLayers()
    if (ftrs.length === 0) {
      setAoiGeoJson(null)
    }
  }

  const helpDisplayStyle = helpDisplayed ? '' : 'none'

  return (
    <OpMapBase
      title='Select area of interest'
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      setMapElement={setMapElement}
      mapElement={mapElement}
      mapPosition={mapPosition}
      setMapPosition={setMapPosition}
      spinnerVis={spinnerVisAny}
      setHelpDisplayed={setHelpDisplayed}
      type='select'
      basemap={basemap}
    >

      <IonPopover
        isOpen={showRpaPopover}
        cssClass='my-custom-class'
        onDidDismiss={e => setShowRpaPopover(false)}
        backdropDismiss={false}
      >
        <div className='rpaPopover'>
          <p>Enter your Single Business Identifier (SBI) to access field boundaries from the RPA web service.</p>
          <IonInput value={inputRpaSbi} placeholder='Enter SBI here' onIonChange={e => setInputRpaSbi(e.detail.value)} />
          <div className='ion-text-end'>
            <IonButton color='primary' onClick={e => sbiSelected(e)}>Okay</IonButton>
          </div>
        </div>
      </IonPopover>

      <GeoJSON
        data={process.env.REACT_APP_INCLUDE_TEST_AREA === 'T' ? testArea : null}
        style={{
          color: '#000000',
          weight: 1,
          opacity: 1,
          fillOpacity: 0
        }}
      />

      <GeoJSON
        key={gisGeoJson ? gisGeoJson.name : null} data={gisGeoJson ? gisGeoJson.gjson : null}
        style={{
          color: '#000000',
          weight: 1,
          opacity: 1,
          fillOpacity: 0
        }}
      />

      <Control position='topright' className='ctlLocate'>
        <div className='leaflet-bar aoi'>
          <button title='Centre map on me' onClick={e => centreOnLocation(e)}><IoLocationOutline fontSize='22px' /></button>
        </div>
      </Control>

      <Control position='topright' className='ctlMapHelpControl'>
        <div className='ctlMapHelp' style={{ display: helpDisplayStyle }}>
          Centre map on current location
        </div>
      </Control>

      {/*
        There is problem with the positioning of the <EditControl>. It always
        appear below controls created with the <Control> control.
      */}
      <FeatureGroup ref={drawnFeatures}>
        <EditControl
          position='topleft'
          edit={{
            edit: false,
            remove: false
          }}
          // onEdited={this._onEditPath}
          onCreated={e => onCreate(e)}
          onDeleted={e => onDelete(e)}
          draw={{
            rectangle: {
              shapeOptions: {
                color: 'black',
                weight: 2
              }
            },
            polygon: false,
            circle: false,
            polyline: false,
            marker: false,
            circlemarker: false
          }}
        />
      </FeatureGroup>

      <Control position='topleft' className='ctlGetLayers'>
        <div className='leaflet-bar aoi'>
          <button title='Get map layers' onClick={e => callGetLayers(e)}><IoColorWandOutline fontSize='22px' /></button>
        </div>
      </Control>

      <Control position='topleft' className='ctlMapHelpControl'>
        <div className='ctlMapHelp' style={{ display: helpDisplayStyle }}>
          Once area of interest defined, generate map layers
        </div>
      </Control>

      <Control position='topleft' className='ctlDelete'>
        <div className='leaflet-bar aoi'>
          <button title='Delete area of interest' onClick={e => deleteAoi(e)}><IoTrashOutline fontSize='22px' /></button>
        </div>
      </Control>

      <Control position='topleft' className='ctlMapHelpControl'>
        <div className='ctlMapHelp' style={{ display: helpDisplayStyle }}>
          Clear the area of interest
        </div>
      </Control>

      <Control position='topleft' className='ctlRpaApi'>
        <div className='leaflet-bar aoi'>
          <button className='acronymButton' title='Set area of interest by RPA ID' onClick={() => { setShowRpaPopover(true) }}>RPA</button>
        </div>
      </Control>

      <Control position='topleft' className='ctlMapHelpControl'>
        <div className='ctlMapHelp' style={{ display: helpDisplayStyle }}>
          Set area of interest with RPA Single Business Idenifier (SBI)
        </div>
      </Control>

      <Control position='topleft' className='ctlFilePicker'>
        <FilePicker extensions={['shp']} onChange={base64 => (shpSelected(base64))}>
          <div className='leaflet-bar aoi'>
            <button className='acronymButton' title='Set area of interest from shapefile'>GIS</button>
          </div>
        </FilePicker>
      </Control>

      <Control position='topleft' className='ctlMapHelpControl'>
        <div className='ctlMapHelp' style={{ display: helpDisplayStyle }}>
          Set area of interest with GIS file
        </div>
      </Control>

      <Control position='topleft' className='ctlMapHelpControl'>
        <div className='ctlMapHelp' style={{ position: 'absolute', top: '51px', width: '300px', display: helpDisplayStyle }}>
          Draw rectangle over your area of interest
        </div>
      </Control>

    </OpMapBase>
  )
}
